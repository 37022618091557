import endOfISOWeek from 'date-fns/endOfISOWeek';
import { gql } from 'graphql-tag';

import { useFeature } from 'common/hooks/useFeature';
import { formatDateToApiString } from 'common/utils/formatDateToApiString';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import type { QueryState } from 'src/core/api/queryState';
import FEATURES from 'src/core/constants/features';

type ActionsCounts = {
  requestsToReview: number;
  invoicesToPay: number;
  paymentsWithoutReceipts: number;
  paymentsToPrepare: number;
};

export const useActionsCountsQuery = (
  shouldFetch: boolean,
): QueryState<ActionsCounts> => {
  const hasPayableFeature = useFeature(FEATURES.BOOKKEEP_PAYABLES);

  const paymentsWithoutReceiptQueryState = useQuery<{
    receiptCount: number;
  }>({
    key: 'paymentsWithoutReceiptCount',
    isEnabled: shouldFetch,
    request: {
      type: 'graphQL',
      target: 'v1',
      query: gql`
        query PaymentsWithoutReceipt($companyId: String!) {
          company(id: $companyId) {
            payments(filters_v2: [{ type: "invoice", value: ["missing"] }]) {
              total
            }
          }
        }
      `,
    },
    reshapeData: (data) => ({
      receiptCount: data.company.payments.total,
    }),
  });

  const requestsToReviewQueryState = useQuery<{ requestsCount: number }>({
    key: 'requestsToReviewCount',
    isEnabled: shouldFetch,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/requests?status[0]=pending',
    },
    reshapeData: (data) => ({
      requestsCount: data.stats.all,
    }),
  });

  const invoicesToPayQueryState = useQuery<{ invoicesCount: number }>({
    key: 'invoicesToPayCount',
    isEnabled: shouldFetch,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/transfer-scheduling/payments-to-schedule-count?type=invoice',
      params: {
        to: formatDateToApiString(endOfISOWeek(new Date())),
      },
    },
    reshapeData: (data) => ({
      invoicesCount: data.paymentsToScheduleCount,
    }),
  });

  const payablesQueryState = useQuery<{
    toPrepareCount: number;
  }>({
    key: 'payablesCounts',
    isEnabled: shouldFetch,
    request: {
      type: 'graphQL',
      target: 'v2',
      query: gql`
        query PayablesCounts($companyId: ID!) {
          company(id: $companyId) {
            payablesToPrepare: payables(
              filter: {
                state: [created, unprepared]
                withBookkeepingStartDate: true
              }
            ) {
              totalCount
            }
          }
        }
      `,
    },
    reshapeData: (data) => ({
      toPrepareCount: hasPayableFeature
        ? data.company.payablesToPrepare.totalCount
        : 0,
    }),
  });

  return useQueryStates({
    states: {
      paymentsWithoutReceipt: paymentsWithoutReceiptQueryState,
      requestsToReview: requestsToReviewQueryState,
      invoicesToPay: invoicesToPayQueryState,
      paymentsToPrepare: payablesQueryState,
    },
    reshapeData: (data) => ({
      paymentsWithoutReceipts: data.paymentsWithoutReceipt.receiptCount,
      requestsToReview: data.requestsToReview.requestsCount,
      invoicesToPay: data.invoicesToPay.invoicesCount,
      paymentsToPrepare: data.paymentsToPrepare.toPrepareCount,
    }),
  });
};
