import { Button } from '@dev-spendesk/grapes';
import React, { type ReactNode, useState } from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import FEATURES from "src/core/constants/features";

import { BetaFeatureFeedbackModal } from './BetaFeatureFeedbackModal';
import styles from './BetaFeatureFeedbackPopover.module.css';
import { BetaFeatureGetPreviousVersionModal } from './BetaFeatureGetPreviousVersionModal';
import { type EXTRA_FEATURES } from '../BetaFeatureNotice';
import { Popover } from '../Popover';
import { useBetaFeatureNoticeTracking } from '../hooks';

export type FeedbackModalVariant = 'default' | 'withRating';

type Props = {
  featureName: FEATURES | EXTRA_FEATURES;
  disableSwitchToPreviousVersion: boolean;
  feedbackTags: string[];
  title?: ReactNode;
  content?: ReactNode;
  buttonText?: string;
  modalVariant?: FeedbackModalVariant;
  isFeedbackMandatory: boolean;
  analyticsContext?: string;
  onClose(): void;
  onFeedbackSent?(): void;
};

export const BetaFeatureFeedbackPopover = ({
  featureName,
  disableSwitchToPreviousVersion,
  feedbackTags,
  title,
  content,
  buttonText,
  modalVariant,
  isFeedbackMandatory,
  analyticsContext,
  onClose,
  onFeedbackSent,
}: Props) => {
  const { t } = useTranslation('global');
  const betaFeatureNoticeTracking = useBetaFeatureNoticeTracking({
    featureName,
  });
  const [isFeatureFeedbackModalOpen, setShowFeatureFeedbackModal] =
    useState(false);

  const [
    isBetaFeatureGetPreviousVersionModalOpen,
    setShowBetaFeatureGetPreviousVersionModal,
  ] = useState(false);

  const popoverTitle =
    title ?? `${t('betaFeature.feedbackNotification.title')} ✨`;
  const popoverContent =
    content ?? t('betaFeature.feedbackNotification.description');

  return (
    <>
      <Popover
        title={popoverTitle}
        content={popoverContent}
        actions={[
          ...(!disableSwitchToPreviousVersion
            ? [
                <Button
                  key="goToOldVersion"
                  onClick={() =>
                    setShowBetaFeatureGetPreviousVersionModal(true)
                  }
                  text={t(
                    'betaFeature.feedbackNotification.returnToPreviousVersion',
                  )}
                  variant="ghost"
                  className="text-white"
                />,
              ]
            : []),
          <Button
            key="giveFeedback"
            text={
              buttonText ?? t('betaFeature.feedbackNotification.giveFeedback')
            }
            variant="contrasted"
            onClick={() => setShowFeatureFeedbackModal(true)}
          />,
        ]}
        className={styles.BetaFeatureFeedbackPopover}
        onClose={onClose}
        disableClickAwayClose={
          isFeedbackMandatory ||
          isBetaFeatureGetPreviousVersionModalOpen ||
          isFeatureFeedbackModalOpen
        }
      />
      {!disableSwitchToPreviousVersion &&
        Object.values(FEATURES).includes(featureName as FEATURES) && (
          <BetaFeatureGetPreviousVersionModal
            featureName={featureName as FEATURES}
            isOpen={isBetaFeatureGetPreviousVersionModalOpen}
            onConfirm={() => {
              betaFeatureNoticeTracking.onSubmitFeedbackAndSwitchToOldVersionButtonClicked();
              onClose();
            }}
            onCancel={() => setShowBetaFeatureGetPreviousVersionModal(false)}
            feedbackTags={feedbackTags}
          />
        )}
      <BetaFeatureFeedbackModal
        isOpen={isFeatureFeedbackModalOpen}
        modalVariant={modalVariant}
        onCancel={() => setShowFeatureFeedbackModal(false)}
        onConfirm={() => {
          betaFeatureNoticeTracking.onSubmitFeedbackButtonClicked();
          if (onFeedbackSent) {
            onFeedbackSent();
          }
          onClose();
        }}
        feedbackTags={feedbackTags}
        analyticsContext={analyticsContext}
      />
    </>
  );
};
