import {
  Button,
  IconButton,
  DropdownMenu,
  DropdownItem,
  Avatar,
  Table,
  Tag,
} from '@dev-spendesk/grapes';

import { GrapesSectionHeader } from 'common/components/GrapesSectionHeader';
import { CenteredLayout } from 'common/components/layout';
import { useTranslation } from 'common/hooks/useTranslation';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { AnalyticalSplitDiscoveryBanner } from 'modules/bookkeep/components/AnalyticalSplitDiscoveryBanner/AnalyticalSplitDiscoveryBanner';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import type { CostCenter, CostCenterWithBudgets } from '../../costCenter';
import type { Member } from '../../member';
import { CostCenterEmptyState } from '../CostCenterEmptyState';
import { ReportingManagerMarketingBanner } from '../ReportingManagerMarketingBanner';

import './CostCentersPage.css';

type Props = {
  members: Member[];
  costCenters: CostCenterWithBudgets[];
  onClickFirstCreate(): void;
  onClickCreate(): void;
  onClickMoreInfo(): void;
  onClickEdit(costCenter: CostCenter): void;
  onClickDelete(costCenter: CostCenterWithBudgets): void;
  onClickImport(): void;
};

export const CostCentersPage = ({
  members,
  costCenters,
  onClickFirstCreate,
  onClickCreate,
  onClickMoreInfo,
  onClickEdit,
  onClickDelete,
  onClickImport,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const isReportingManagerFeatureEnabled = useFeature(
    FEATURES.TMP_CONMAN_REPORTING_MANAGERS,
  );

  if (costCenters.length === 0) {
    return <CostCenterEmptyState onCreateClick={onClickFirstCreate} />;
  }

  const sortedCostCenters = costCenters.sort((cc1, cc2) => {
    return cc1.name.localeCompare(cc2.name, activeLanguage, {
      sensitivity: 'base',
    });
  });

  return (
    <CenteredLayout className="CostCentersPage" width="medium">
      <GrapesSectionHeader
        className={hasNewNavigation ? '' : 'mt-xl'}
        title={
          <div className="flex items-center">
            {t('costCenters.costCenter')}
            {isAnalyticalSplitActivated ? (
              <Tag className="ml-xs" variant="neutral">
                {t('costCenters.splittable')}
              </Tag>
            ) : null}
          </div>
        }
        subtitle={
          <>
            <p>
              {isAnalyticalSplitActivated
                ? t('costCenters.splittableCostCenterDescription')
                : t('costCenters.costCenterDescription')}
            </p>
            <button
              type="button"
              className="CostCentersPage__link body-m"
              onClick={onClickMoreInfo}
            >
              {t('costCenters.moreInformation')}
            </button>
          </>
        }
        leftContent={
          <DropdownMenu
            options={[
              {
                key: 'create',
                label: t('costCenters.addCostCenterDropDown.createLabel'),
              },
              {
                key: 'import',
                label: t('costCenters.addCostCenterDropDown.importLabel'),
              },
            ]}
            renderButton={(getToggleButtonProps) => (
              <Button
                {...getToggleButtonProps()}
                text={t('costCenters.addCostCenterDropDown.text')}
                variant="primary"
              />
            )}
            renderOption={(option) => <DropdownItem label={option.label} />}
            onSelect={(option) => {
              switch (option.key) {
                case 'import':
                  onClickImport();
                  break;
                case 'create':
                  onClickCreate();
                  break;
                default:
                  break;
              }
            }}
          />
        }
      />
      {isReportingManagerFeatureEnabled && (
        <ReportingManagerMarketingBanner
          variant="page"
          className="mb-s"
          title={t('costCenters.banner.title')}
          description={t('costCenters.banner.description')}
        />
      )}
      <Table
        columns={[
          {
            id: 'costCenter',
            header: t('costCenters.costCenter'),
            width: '50%',
            renderCell: (row) => row.name,
          },
          {
            id: 'owner',
            header: t('costCenters.owner'),
            width: '50%',
            renderCell: (row, { isRowHovered }) => {
              const owner = members.find((member) => member.id === row.ownerId);
              return (
                <div className="CostCentersPage__owner-cell">
                  {owner && (
                    <>
                      <Avatar
                        src={owner.avatar}
                        text={owner.isPending ? owner.email : owner.fullname}
                        className="CostCentersPage__owner-cell-avatar"
                      />
                      <div className="CostCentersPage__owner-cell__text">
                        {owner.isPending ? owner.email : owner.fullname}
                      </div>
                    </>
                  )}

                  {isRowHovered && (
                    <div className="CostCentersPage__owner-cell__actions">
                      <IconButton
                        iconName="pen"
                        variant="borderless"
                        onClick={() => {
                          onClickEdit(row);
                        }}
                        hasNegativeMargins
                        aria-label={t('misc.edit')}
                      />
                      <IconButton
                        iconName="trash"
                        variant="borderless"
                        onClick={() => {
                          onClickDelete(row);
                        }}
                        hasNegativeMargins
                        aria-label={t('misc.delete')}
                      />
                    </div>
                  )}
                </div>
              );
            },
          },
        ]}
        data={sortedCostCenters}
        rowHeight="compact"
        hasColumnSeparator
        getRowId={(row) => row.id}
      />

      <AnalyticalSplitDiscoveryBanner />
    </CenteredLayout>
  );
};
