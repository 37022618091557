import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { normalizeString } from 'common/utils/normalizeString';
import { type Language } from 'src/core/config/i18n';

import { type CustomField } from './customField';

export const dateFormats = [
  ['DD', 'MM'],
  ['DD', 'MM', 'YY'],
  ['DD', 'MM', 'YYYY'],
  ['MM', 'DD'],
  ['MM', 'DD', 'YY'],
  ['MM', 'DD', 'YYYY'],
  ['YY', 'MM', 'DD'],
  ['YYYY', 'MM', 'DD'],
  ['MM'],
  ['DD'],
  ['YY'],
  ['YYYY'],
] as const;
export type DateFormat = (typeof dateFormats)[number];

const compositeDateFormats = ['DD', 'MM', 'YYYY'] as const;
type CompositeDateFormat = (typeof compositeDateFormats)[number];

export const dateDelimiters = ['.', '-', '/', ''] as const;
export type DateDelimiter = (typeof dateDelimiters)[number];

export const decimalDelimiters = ['.', ','] as const;
export type DecimalDelimiter = (typeof decimalDelimiters)[number];

export const columnDelimiters = [';', ','] as const;
export type ColumnDelimiter = (typeof columnDelimiters)[number];

export const analyticalLineOptions = ['new', 'all', 'common'] as const;
export type AnalyticalLineOption = (typeof analyticalLineOptions)[number];

const rowGroups = ['expense', 'analytical', 'vat', 'supplier'] as const;
type RowGroup = (typeof rowGroups)[number];

export const rowDisplayOptions: {
  group: RowGroup;
  active: boolean;
  analyticalCommonLine?: { active: boolean };
}[] = [
  { group: 'expense', active: false },
  {
    group: 'analytical',
    active: false,
    analyticalCommonLine: { active: false },
  },
  { group: 'vat', active: false },
  { group: 'supplier', active: false },
];
export type RowDisplayOption = (typeof rowDisplayOptions)[number];

export type ColumnDisplayOptions = {
  [key in RowGroup]: {
    active: boolean;
    analyticalOption?: AnalyticalLineOption;
  };
};

type OutputFormat = 'csv';

export interface BaseColumn {
  name: string;
  description: string;
  isRequired: boolean;
  isReorderable: boolean;
  isDeprecated?: boolean;
  maxLength?: number;
  displayOptions?: ColumnDisplayOptions;
}

export interface DefinedColumn extends BaseColumn {
  type: 'defined';
  reference: string;
}

const isDefinedColumn = (column: Column): column is DefinedColumn => {
  return column.type === 'defined';
};

export interface CustomFieldColumn extends BaseColumn {
  type: 'customField';
  customFieldId: string;
}

const isCustomFieldColumn = (column: Column): column is CustomFieldColumn => {
  return column.type === 'customField';
};

type CustomFieldCompositePatternPart = {
  type: 'customField';
  customFieldId: string;
};

const isCustomFieldCompositePatternPart = (
  compositePatternPart: CompositePatternPart,
): compositePatternPart is CustomFieldCompositePatternPart => {
  return compositePatternPart.type === 'customField';
};

type DefinedCompositePatternPart = {
  type: 'defined';
  reference: string;
  isDeprecated?: boolean;
};

type DateCompositePatternPart = {
  type: 'date';
  reference: string;
  isDeprecated?: boolean;
  format: CompositeDateFormat;
};

const isDefinedCompositePatternPart = (
  compositePatternPart: CompositePatternPart,
): compositePatternPart is DefinedCompositePatternPart => {
  return compositePatternPart.type === 'defined';
};

const isDateCompositePatternPart = (
  compositePatternPart: CompositePatternPart,
): compositePatternPart is DateCompositePatternPart => {
  return compositePatternPart.type === 'date';
};

type StaticCompositePatternPart = {
  type: 'static';
  value: string;
};

const isStaticCompositePatternPart = (
  compositePatternPart: CompositePatternPart,
): compositePatternPart is StaticCompositePatternPart => {
  return compositePatternPart.type === 'static';
};

export type CompositePatternPart =
  | CustomFieldCompositePatternPart
  | DefinedCompositePatternPart
  | DateCompositePatternPart
  | StaticCompositePatternPart;

export interface CompositeColumn extends BaseColumn {
  type: 'composite';
  compositePattern: CompositePatternPart[];
}

export interface JournalCodeColumn extends BaseColumn {
  type: 'journalCode';
  cardExpense: string;
  invoice: string;
  expenseClaim: string;
  subscription: string;
}

const isJournalCodeColumn = (column: Column): column is JournalCodeColumn => {
  return column.type === 'journalCode';
};

export interface DirectionColumn extends BaseColumn {
  type: 'direction';
  debit: string;
  credit: string;
}

const isDirectionColumn = (column: Column): column is DirectionColumn => {
  return column.type === 'direction';
};

export interface TypeColumn extends BaseColumn {
  type: 'type';
  analytical: string;
  general: string;
}

const isTypeColumn = (column: Column): column is TypeColumn => {
  return column.type === 'type';
};

export const isCompositeColumn = (
  column: Column,
): column is CompositeColumn => {
  return column.type === 'composite';
};

export type Column =
  | DefinedColumn
  | CustomFieldColumn
  | CompositeColumn
  | JournalCodeColumn
  | DirectionColumn
  | TypeColumn;

export type AvailableColumn = {
  reference: string;
  description: string;
  isRequired: boolean;
  isReorderable: boolean;
  isDeprecated?: boolean;
  type?: 'date' | 'type' | 'direction' | 'journalCode';
  name: string;
  displayOptions?: ColumnDisplayOptions;
};

export type Template = {
  id?: string;
  name: string;
  dateFormat: DateFormat;
  dateDelimiter: DateDelimiter;
  decimalDelimiter: DecimalDelimiter;
  columnDelimiter: ColumnDelimiter;
  shouldOutputHeaders: boolean;
  outputFormat: OutputFormat;
  columns: Column[];
  language: 'en-GB' | 'fr' | 'de';
  accountingSoftware: string;
  customSoftwareName?: string;
  rowDisplayOptions?: RowDisplayOption[];
};

export type TemplateItem = {
  id: string;
  name: string;
  type: 'purchase' | 'bank';
  isDefault: boolean;
};

class UnknownDateDelimiterError extends Error {
  constructor(delimiter: never) {
    super(`Unknown date delimiter: ${delimiter}`);
  }
}

export const dateDelimiterToTranslationKey = (
  delimiter: DateDelimiter,
): I18nKey => {
  switch (delimiter) {
    case '-':
      return 'exports.hyphen';
    case '.':
      return 'exports.fullDot';
    case '/':
      return 'exports.forwardSlash';
    case '':
      return 'exports.noDelimiter';
    default:
      throw new UnknownDateDelimiterError(delimiter);
  }
};

class UnknownDecimalDelimiterError extends Error {
  constructor(delimiter: never) {
    super(`Unknown decimal delimiter: ${delimiter}`);
  }
}

export const decimalDelimiterToTranslationKey = (
  delimiter: DecimalDelimiter,
): I18nKey => {
  switch (delimiter) {
    case '.':
      return 'exports.fullDot';
    case ',':
      return 'exports.comma';
    default:
      throw new UnknownDecimalDelimiterError(delimiter);
  }
};

class UnknownColumnDelimiterError extends Error {
  constructor(delimiter: never) {
    super(`Unknown column delimiter: ${delimiter}`);
  }
}

export const columnDelimiterToTranslationKey = (
  delimiter: ColumnDelimiter,
): I18nKey => {
  switch (delimiter) {
    case ',':
      return 'exports.comma';
    case ';':
      return 'exports.semicolon';
    default:
      throw new UnknownColumnDelimiterError(delimiter);
  }
};

export const languageToTranslationKey = (language: Language): I18nKey => {
  switch (language) {
    case 'de':
      return 'bookkeep.settings.export.languages.de';
    case 'fr':
      return 'bookkeep.settings.export.languages.fr';
    case 'it':
      return 'bookkeep.settings.export.languages.it';
    case 'es':
      return 'bookkeep.settings.export.languages.es';
    default:
      return 'bookkeep.settings.export.languages.en';
  }
};

export const getTranslatedDefinedColumnName = (
  reference: string,
  t: TGlobalFunctionTyped,
): string => {
  switch (reference) {
    case 'entryNumber':
      return t('bookkeep.settings.export.columns.entryNumber.name');
    case 'accountingEntryId':
      return t('bookkeep.settings.export.columns.accountingEntryId.name');
    case 'payableDate':
      return t('bookkeep.settings.export.columns.payableDate.name');
    case 'accountingDate':
      return t('bookkeep.settings.export.columns.accountingDate.name');
    case 'month':
      return t('bookkeep.settings.export.columns.month.name');
    case 'payer':
      return t('bookkeep.settings.export.columns.payer.name');
    case 'team':
      return t('bookkeep.settings.export.columns.team.name');
    case 'description':
      return t('bookkeep.settings.export.columns.description.name');
    case 'supplier':
      return t('bookkeep.settings.export.columns.supplier.name');
    case 'accountName':
      return t('bookkeep.settings.export.columns.accountName.name');
    case 'account':
      return t('bookkeep.settings.export.columns.account.name');
    case 'debit':
      return t('bookkeep.settings.export.columns.debit.name');
    case 'credit':
      return t('bookkeep.settings.export.columns.credit.name');
    case 'netAmountInDeclaredCurrency':
      return t(
        'bookkeep.settings.export.columns.netAmountInDeclaredCurrency.name',
      );
    case 'currency':
      return t('bookkeep.settings.export.columns.currency.name');
    case 'signedLocalAmount':
      return t('bookkeep.settings.export.columns.signedLocalAmount.name');
    case 'localCurrency':
      return t('bookkeep.settings.export.columns.localCurrency.name');
    case 'requestAmount':
      return t('bookkeep.settings.export.columns.requestAmount.name');
    case 'requestCurrency':
      return t('bookkeep.settings.export.columns.requestCurrency.name');
    case 'payableType':
      return t('bookkeep.settings.export.columns.payableType.name');
    case 'payableSubType':
      return t('bookkeep.settings.export.columns.payableSubType.name');
    case 'hasReceipt':
      return t('bookkeep.settings.export.columns.hasReceipt.name');
    case 'receiptUploadDate':
      return t('bookkeep.settings.export.columns.receiptUploadDate.name');
    case 'receiptNames':
      return t('bookkeep.settings.export.columns.receiptNames.name');
    case 'receiptUrls':
      return t('bookkeep.settings.export.columns.receiptUrls.name');
    case 'invoiceNumber':
      return t('bookkeep.settings.export.columns.invoiceNumber.name');
    case 'payableReference':
      return t('bookkeep.settings.export.columns.payableReference.name');
    case 'costCenter':
      return t('bookkeep.settings.export.columns.costCenter.name');
    case 'expenseAccount':
      return t('bookkeep.settings.export.columns.expenseAccount.name');
    case 'taxAccount':
      return t('bookkeep.settings.export.columns.taxAccount.name');
    case 'accountPayable':
      return t('bookkeep.settings.export.columns.accountPayable.name');
    case 'netAmount':
      return t('bookkeep.settings.export.columns.netAmount.name');
    case 'taxAmount':
      return t('bookkeep.settings.export.columns.taxAmount.name');
    case 'grossAmount':
      return t('bookkeep.settings.export.columns.grossAmount.name');
    case 'firstExportedAt':
      return t('bookkeep.settings.export.columns.firstExportedAt.name');
    case 'dueDate':
      return t('bookkeep.settings.export.columns.dueDate.name');
    case 'type':
      return t('bookkeep.settings.export.columns.type.name');
    case 'direction':
      return t('bookkeep.settings.export.columns.direction.name');
    case 'amortisationStartDate':
      return t('bookkeep.settings.export.columns.amortisationStartDate.name');
    case 'amortisationEndDate':
      return t('bookkeep.settings.export.columns.amortisationEndDate.name');
    default:
      return reference;
  }
};

export const getTranslatedDefinedColumnDesc = (
  reference: string,
  t: TGlobalFunctionTyped,
): string => {
  switch (reference) {
    case 'entryNumber':
      return t('bookkeep.settings.export.columns.entryNumber.description');
    case 'accountingEntryId':
      return t(
        'bookkeep.settings.export.columns.accountingEntryId.description',
      );
    case 'payableDate':
      return t('bookkeep.settings.export.columns.payableDate.description');
    case 'accountingDate':
      return t('bookkeep.settings.export.columns.accountingDate.description');
    case 'month':
      return t('bookkeep.settings.export.columns.month.description');
    case 'payer':
      return t('bookkeep.settings.export.columns.payer.description');
    case 'team':
      return t('bookkeep.settings.export.columns.team.description');
    case 'description':
      return t('bookkeep.settings.export.columns.description.description');
    case 'supplier':
      return t('bookkeep.settings.export.columns.supplier.description');
    case 'accountName':
      return t('bookkeep.settings.export.columns.accountName.description');
    case 'account':
      return t('bookkeep.settings.export.columns.account.description');
    case 'debit':
      return t('bookkeep.settings.export.columns.debit.description');
    case 'credit':
      return t('bookkeep.settings.export.columns.credit.description');
    case 'netAmountInDeclaredCurrency':
      return t(
        'bookkeep.settings.export.columns.netAmountInDeclaredCurrency.description',
      );
    case 'currency':
      return t('bookkeep.settings.export.columns.currency.description');
    case 'signedLocalAmount':
      return t(
        'bookkeep.settings.export.columns.signedLocalAmount.description',
      );
    case 'localCurrency':
      return t('bookkeep.settings.export.columns.localCurrency.description');
    case 'requestAmount':
      return t('bookkeep.settings.export.columns.requestAmount.description');
    case 'requestCurrency':
      return t('bookkeep.settings.export.columns.requestCurrency.description');
    case 'payableType':
      return t('bookkeep.settings.export.columns.payableType.description');
    case 'payableSubType':
      return t('bookkeep.settings.export.columns.payableSubType.description');
    case 'hasReceipt':
      return t('bookkeep.settings.export.columns.hasReceipt.description');
    case 'receiptUploadDate':
      return t(
        'bookkeep.settings.export.columns.receiptUploadDate.description',
      );
    case 'receiptNames':
      return t('bookkeep.settings.export.columns.receiptNames.description');
    case 'receiptUrls':
      return t('bookkeep.settings.export.columns.receiptUrls.description');
    case 'invoiceNumber':
      return t('bookkeep.settings.export.columns.invoiceNumber.description');
    case 'payableReference':
      return t('bookkeep.settings.export.columns.payableReference.description');
    case 'costCenter':
      return t('bookkeep.settings.export.columns.costCenter.description');
    case 'expenseAccount':
      return t('bookkeep.settings.export.columns.expenseAccount.description');
    case 'taxAccount':
      return t('bookkeep.settings.export.columns.taxAccount.description');
    case 'accountPayable':
      return t('bookkeep.settings.export.columns.accountPayable.description');
    case 'netAmount':
      return t('bookkeep.settings.export.columns.netAmount.description');
    case 'taxAmount':
      return t('bookkeep.settings.export.columns.taxAmount.description');
    case 'grossAmount':
      return t('bookkeep.settings.export.columns.grossAmount.description');
    case 'firstExportedAt':
      return t('bookkeep.settings.export.columns.firstExportedAt.description');
    case 'dueDate':
      return t('bookkeep.settings.export.columns.dueDate.description');
    case 'type':
      return t('bookkeep.settings.export.columns.type.description');
    case 'direction':
      return t('bookkeep.settings.export.columns.direction.description');
    case 'amortisationStartDate':
      return t(
        'bookkeep.settings.export.columns.amortisationStartDate.description',
      );
    case 'amortisationEndDate':
      return t(
        'bookkeep.settings.export.columns.amortisationEndDate.description',
      );
    default:
      return '';
  }
};

export const getTranslatedCustomFieldColumnDesc = (
  customFieldId: string,
  customFields: CustomField[],
  t: TGlobalFunctionTyped,
): string => {
  const customField = customFields.find(({ id }) => id === customFieldId);

  return t('bookkeep.settings.export.columns.customField.description', {
    name: customField?.name,
  });
};

export const parseDateFormatWithEmptyDelimiter = (
  format: string,
): DateFormat => {
  const output = [];
  let lastChunkIndex = 0;

  // Loop through all the characters of the string and split it in chunks by
  // finding where the next character differs from the current one.
  for (let index = 0; index < format.length; index++) {
    if (format.charAt(index) !== format.charAt(index + 1)) {
      output.push(format.slice(lastChunkIndex, index + 1));
      lastChunkIndex = index + 1;
    }
  }

  return output as unknown as DateFormat;
};

export const parseDateFormat = (
  format: string,
  delimiter: DateDelimiter,
): DateFormat => {
  if (delimiter === '') {
    return parseDateFormatWithEmptyDelimiter(format);
  }

  return format.split(delimiter) as unknown as DateFormat;
};

const customFieldToCustomFieldColumn = (
  customField: CustomField,
  t: TGlobalFunctionTyped,
): CustomFieldColumn => ({
  name: customField.name,
  description: t('bookkeep.settings.export.columns.customField.description', {
    name: customField.name,
  }),
  type: 'customField',
  customFieldId: customField.id,
  isRequired: false,
  isReorderable: true,
});

const availableColumnToColumn = (availableColumn: AvailableColumn): Column => {
  switch (availableColumn.type) {
    case 'type':
      return {
        name: availableColumn.name,
        type: 'type',
        general: 'G',
        analytical: 'A',
        description: availableColumn.description,
        isRequired: availableColumn.isRequired,
        isReorderable: availableColumn.isReorderable,
        displayOptions: availableColumn.displayOptions,
      };
    case 'direction':
      return {
        name: availableColumn.name,
        type: 'direction',
        debit: 'D',
        credit: 'C',
        description: availableColumn.description,
        isRequired: availableColumn.isRequired,
        isReorderable: availableColumn.isReorderable,
        displayOptions: availableColumn.displayOptions,
      };
    default:
      return {
        type: 'defined',
        reference: availableColumn.reference,
        name: availableColumn.name,
        description: availableColumn.description,
        isRequired: availableColumn.isRequired,
        isReorderable: availableColumn.isReorderable,
        displayOptions: availableColumn.displayOptions,
      };
  }
};

type DefinedCompositePatternPartOption = DefinedCompositePatternPart & {
  id: string;
  name: string;
};

type DateCompositePatternPartOption = DateCompositePatternPart & {
  id: string;
  name: string;
};

const isDefinedCompositePatternPartOption = (
  option: CompositeOption,
): option is DefinedCompositePatternPartOption => {
  return 'type' in option && option.type === 'defined';
};

const isDateCompositePatternPartOption = (
  option: CompositeOption,
): option is DateCompositePatternPartOption => {
  return 'type' in option && option.type === 'date';
};

type CustomFieldCompositePatternPartOption = CustomFieldCompositePatternPart & {
  id: string;
  name: string;
};

const isCustomFieldCompositePatternPartOption = (
  option: CompositeOption,
): option is DefinedCompositePatternPartOption => {
  return 'type' in option && option.type === 'customField';
};

type CompositePatternPartOption =
  | DefinedCompositePatternPartOption
  | DateCompositePatternPartOption
  | CustomFieldCompositePatternPartOption;

type ParentCompositeOption = {
  id: string;
  name: string;
  subItems: CompositePatternPartOption[];
};

const isParentCompositeOption = (
  option: CompositeOption,
): option is ParentCompositeOption => {
  return 'subItems' in option;
};

export type PreviewRow = {
  [key: string]: string | number | boolean | undefined;
};

export type CompositeOption =
  | CompositePatternPartOption
  | ParentCompositeOption;

export const getCompositePatternPartOptions = ({
  customFields,
  availableColumns,
  t,
}: {
  customFields: CustomField[];
  availableColumns: AvailableColumn[];
  t: TGlobalFunctionTyped;
}): CompositeOption[] => {
  const definedCompositePatternPartOptions: CompositePatternPartOption[] =
    availableColumns.flatMap((column) =>
      column?.type !== 'date'
        ? [
            {
              id: column.reference,
              type: 'defined',
              reference: column.reference,
              name: getTranslatedDefinedColumnName(column.reference, t),
            },
          ]
        : [],
    );

  const dateCompositePatternPartOptions: ParentCompositeOption[] =
    availableColumns.flatMap((column) =>
      column.type === 'date'
        ? [
            {
              id: column.reference,
              name: getTranslatedDefinedColumnName(column.reference, t),
              subItems: compositeDateFormats.map(
                (format: CompositeDateFormat) => ({
                  id: `${column.reference}${format}`,
                  type: 'date',
                  reference: column.reference,
                  name: format,
                  format,
                }),
              ),
            },
          ]
        : [],
    );

  let compositePatternPartOptions: CompositeOption[] = [
    ...definedCompositePatternPartOptions,
    ...dateCompositePatternPartOptions,
  ].sort((a, b) => +isParentCompositeOption(b) - +isParentCompositeOption(a));

  if (customFields.length > 0) {
    const customFieldCompositePatternPartOptions = customFields.map(
      (customField) => {
        const option: CustomFieldCompositePatternPartOption = {
          id: customField.id,
          type: 'customField',
          customFieldId: customField.id,
          name: customField.name,
        };

        return option;
      },
    );
    const customFieldsOption: ParentCompositeOption = {
      id: 'customFields',
      name: t('bookkeep.settings.export.parentCompositeOptions.customFields'),
      subItems: customFieldCompositePatternPartOptions,
    };

    compositePatternPartOptions = [
      customFieldsOption,
      ...compositePatternPartOptions,
    ];
  }

  return compositePatternPartOptions;
};

const compositeOptionToCompositePatternPart = (
  option: CompositeOption,
): CompositePatternPart => {
  if (isDefinedCompositePatternPartOption(option)) {
    const compositePatternPart: DefinedCompositePatternPart = {
      type: 'defined',
      reference: option.reference,
    };
    return compositePatternPart;
  }

  if (isDateCompositePatternPartOption(option)) {
    const compositePatternPart: DateCompositePatternPart = {
      type: 'date',
      reference: option.reference,
      format: option.format,
    };
    return compositePatternPart;
  }

  if (isCustomFieldCompositePatternPartOption(option)) {
    const compositePatternPart: CustomFieldCompositePatternPart = {
      type: 'customField',
      customFieldId: option.id,
    };

    return compositePatternPart;
  }

  throw new TypeError(
    `Cannot transform composite option to composite pattern part. Received: ${option}`,
  );
};

const buildStaticCompositePatternPart = (
  value: string,
): StaticCompositePatternPart => ({
  type: 'static',
  value,
});

export const getCompositePatternValue = (
  compositePattern: CompositePatternPart[],
): string[] => {
  return compositePattern.map((compositePatternPart) => {
    if (isDefinedCompositePatternPart(compositePatternPart)) {
      return compositePatternPart.reference;
    }

    if (isDateCompositePatternPart(compositePatternPart)) {
      return `${compositePatternPart.reference}${compositePatternPart.format}`;
    }

    if (isCustomFieldCompositePatternPart(compositePatternPart)) {
      return compositePatternPart.customFieldId;
    }

    if (isStaticCompositePatternPart(compositePatternPart)) {
      return compositePatternPart.value;
    }

    throw new TypeError(
      `Cannot transform composite pattern part to value. Received: ${compositePatternPart}`,
    );
  });
};

const buildEmptyCompositeColumn = (
  t: TGlobalFunctionTyped,
): CompositeColumn => ({
  type: 'composite',
  name: t('bookkeep.settings.export.columns.composite.name'),
  description: t('bookkeep.settings.export.columns.composite.description'),
  isRequired: false,
  isReorderable: true,
  compositePattern: [],
});

const buildEmptyJournalCodeColumn = (
  t: TGlobalFunctionTyped,
): JournalCodeColumn => ({
  type: 'journalCode',
  name: t('bookkeep.settings.export.columns.journalCode.name'),
  description: t('bookkeep.settings.export.columns.journalCode.description'),
  isRequired: false,
  isReorderable: true,
  cardExpense: 'ACH',
  expenseClaim: 'ACH',
  invoice: 'ACH',
  subscription: 'ACH',
});

export const customContentValueToCompositeOption = (
  options: CompositeOption[],
  value: string,
  t: TGlobalFunctionTyped,
): CompositeOption | undefined => {
  for (const option of options) {
    if (isParentCompositeOption(option)) {
      const subOption = option.subItems.find((subItem) => subItem.id === value);

      if (subOption) {
        if (subOption?.type === 'date') {
          return {
            ...subOption,
            name: `${getTranslatedDefinedColumnName(subOption.reference, t)} ${
              subOption.format
            }`,
          };
        }

        return subOption;
      }
    }

    if (option.id === value) {
      return option;
    }
  }
};

export const customContentValueToCompositePatternPart = (
  options: CompositeOption[],
  value: string,
): CompositePatternPart => {
  for (const option of options) {
    if (isParentCompositeOption(option)) {
      const subOption = option.subItems.find((subItem) => subItem.id === value);

      if (subOption) {
        return compositeOptionToCompositePatternPart(subOption);
      }
    }

    if (option.id === value) {
      return compositeOptionToCompositePatternPart(option);
    }
  }

  return buildStaticCompositePatternPart(value);
};

export const searchAvailableColumns = ({
  columnsInUse,
  availableTemplateColumns,
  customFields,
  query,
  t,
}: {
  columnsInUse: Column[];
  availableTemplateColumns: AvailableColumn[];
  customFields: CustomField[];
  query: string;
  t: TGlobalFunctionTyped;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): Column[] => {
  const customFieldsColumns = customFields.map((customField) =>
    customFieldToCustomFieldColumn(customField, t),
  );
  const compositeColumn = buildEmptyCompositeColumn(t);
  const journalCodeColumn = buildEmptyJournalCodeColumn(t);

  // Start with all possible columns
  const allAvailableColumns = availableTemplateColumns
    .map(availableColumnToColumn)
    .concat(customFieldsColumns)
    .concat(journalCodeColumn)
    .concat(compositeColumn);

  const unusedAvailableColumns = [];

  // Loop through used columns and remove them from the list of unused ones
  for (const availableColumn of allAvailableColumns) {
    if (availableColumn.isDeprecated) {
      continue;
    } else if (isDefinedColumn(availableColumn)) {
      const isAlreadyUsed = columnsInUse.some(
        (columnInUse) =>
          isDefinedColumn(columnInUse) &&
          columnInUse.reference === availableColumn.reference,
      );

      if (!isAlreadyUsed) {
        unusedAvailableColumns.push(availableColumn);
      }
    } else if (isCustomFieldColumn(availableColumn)) {
      const isAlreadyUsed = columnsInUse.some(
        (columnInUse) =>
          isCustomFieldColumn(columnInUse) &&
          columnInUse.customFieldId === availableColumn.customFieldId,
      );

      if (!isAlreadyUsed) {
        unusedAvailableColumns.push(availableColumn);
      }
    } else if (isJournalCodeColumn(availableColumn)) {
      const isAlreadyUsed = columnsInUse.some((columnInUse) =>
        isJournalCodeColumn(columnInUse),
      );

      if (!isAlreadyUsed) {
        unusedAvailableColumns.push(availableColumn);
      }
    } else if (isDirectionColumn(availableColumn)) {
      const isAlreadyUsed = columnsInUse.some((columnInUse) =>
        isDirectionColumn(columnInUse),
      );

      if (!isAlreadyUsed) {
        unusedAvailableColumns.push(availableColumn);
      }
    } else if (isTypeColumn(availableColumn)) {
      const isAlreadyUsed = columnsInUse.some((columnInUse) =>
        isTypeColumn(columnInUse),
      );

      if (!isAlreadyUsed) {
        unusedAvailableColumns.push(availableColumn);
      }
    } else {
      unusedAvailableColumns.push(availableColumn);
    }
  }

  // Apply the text filter typed by the user against the unused columns left
  return unusedAvailableColumns.filter((column) => {
    const deburredItemName = normalizeString(column.name);
    const deburredQuery = normalizeString(query);
    return deburredItemName.includes(deburredQuery);
  });
};
