import { Icon, IconButton, type TriggerProps } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import type { CompanyWalletSummary } from 'src/core/modules/company/companyWalletSummary';
import { CompanyDropdown } from 'src/core/modules/company/components/CompanyDropdown/CompanyDropdown';
import { useCompanyWalletSummariesQuery } from 'src/core/modules/company/hooks/useCompanyWalletSummariesQuery';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import './CompanyHeader.css';
import { Logo } from '../Logo/Logo';
import { toggleForceToExpandNavigation } from '../NavigationContext';

type CompanyHeaderContentProps = {
  showWallet: boolean;
  companyName: string;
  companyWallet?: CompanyWalletSummary;
};

const CompanyHeaderContent = ({
  showWallet,
  companyName,
  companyWallet,
}: CompanyHeaderContentProps) => {
  return (
    <>
      <Icon name="building" className="navigation-company-content-shrink" />
      <div className="navigation-company-content navigation-company-translate">
        <h1 className="whitespace-break-spaces break-all text-white title-m">
          {companyName}
        </h1>
        {showWallet && companyWallet && 'walletDetails' in companyWallet && (
          <p className="text-neutral body-s">
            {formatMonetaryValue(companyWallet.totalAmount)}
          </p>
        )}
      </div>
    </>
  );
};

export const CompanyHeader = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const companyId = company.id;

  const companyWalletSummariesQueryState =
    useCompanyWalletSummariesQuery(companyId);

  if (companyWalletSummariesQueryState.status !== 'success') {
    return (
      <div className="navigation-company-header">
        <CompanyHeaderContent showWallet={false} companyName={company.name} />
      </div>
    );
  }

  const companyWallets = companyWalletSummariesQueryState.data;

  if (
    companyWallets.length === 0 ||
    (companyWallets.length === 1 && !user.is_controller)
  ) {
    return (
      <div className="navigation-company-header">
        <CompanyHeaderContent showWallet={false} companyName={company.name} />
      </div>
    );
  }

  return (
    <CompanyDropdown
      companies={companyWallets}
      className="popover-hack-align w-full"
      placement="end-bottom"
    >
      {({ isDropdown, ...triggerProps }: TriggerProps) => (
        <div className="ml-xxs flex items-center justify-between gap-xs">
          <button
            className="navigation-company-switcher"
            {...triggerProps}
            type="button"
          >
            <Logo companyName={company.name} />
          </button>
          <IconButton
            className="navigation-toggle-display ml-auto text-white"
            iconName="drawer"
            aria-label={t('navigation.expandedOrCollapseNavigation')}
            onClick={() => {
              toggleForceToExpandNavigation();
            }}
          />
        </div>
      )}
    </CompanyDropdown>
  );
};
