import { Button, Panel } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
};
export const FloatingPanel = ({ children, isOpen, onClose }: Props) => {
  return isOpen ? (
    <Panel
      title="Workflow"
      onClose={onClose}
      className="absolute right-s top-s z-50 m-0 h-[calc(100%_-_32px)] w-[410px] overflow-y-auto shadow-40"
      footer={
        <div className="flex justify-between gap-s">
          <Button
            text="Save"
            className="flex-grow"
            variant="primary"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            text="Cancel"
            className="flex-grow"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      }
    >
      {children}
    </Panel>
  ) : null;
};
