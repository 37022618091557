import { ModalSlideshow } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompleteOnboardingUserTaskMutation } from 'modules/onboarding/hooks/useCompleteOnboardingUserTaskMutation';
import { useGetOnboardingUserTasksQuery } from 'modules/onboarding/hooks/useGetOnboardingUserTasksQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import type { Language } from 'src/core/config/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import maurice from './assets/maurice.svg';
import subscriptions from './assets/subscriptions.svg';
import { type UserTaskType } from '../../types/userTask';

const getSlideTwoAsset = (lang: Language) => {
  switch (lang) {
    case 'de':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-2-de.mp4';
    case 'fr':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-2-fr.mp4';
    default:
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-2-en.mp4';
  }
};

const getSlideThreeAsset = (lang: Language) => {
  switch (lang) {
    case 'de':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-3-de.mp4';
    case 'fr':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-3-fr.mp4';
    default:
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/onboarding/slideshows/welcome-3-en.mp4';
  }
};

const WELCOME_TASK_TYPE: UserTaskType = 'welcome';

export const WelcomeModal = () => {
  const { activeLanguage, t } = useTranslation('global');

  const userTasksQueryState = useGetOnboardingUserTasksQuery();
  const userTasks = unwrapQuery(userTasksQueryState);
  const userTask = userTasks?.find((task) => task.type === WELCOME_TASK_TYPE);
  const isTaskOngoing = userTask?.status === 'ongoing';

  const [isOpen, setIsOpen] = useState(userTask?.status === 'ongoing');
  const [hasClosed, setHasClosed] = useState(false);

  const [completeOnboardingUserTaskMutation] =
    useCompleteOnboardingUserTaskMutation();

  const slides = [
    {
      title: t('readyToSpend.slideshows.welcome.one.title'),
      content: <Trans i18nKey="readyToSpend.slideshows.welcome.one.content" />,
      illustration: <img src={maurice} alt="" />,
    },
    {
      title: t('readyToSpend.slideshows.welcome.two.title'),
      content: (
        <div>
          <p className="mb-m body-l">
            {t('readyToSpend.slideshows.welcome.two.content')}
          </p>
          <ol className="flex list-inside list-decimal flex-col gap-s">
            <li className="title-m">
              {t('readyToSpend.slideshows.welcome.two.septOne')}
            </li>
            <li className="title-m">
              {t('readyToSpend.slideshows.welcome.two.septTwo')}
            </li>
            <li className="title-m">
              {t('readyToSpend.slideshows.welcome.two.septThree')}
            </li>
          </ol>
        </div>
      ),
      illustration: (
        <video loop muted autoPlay>
          <source src={getSlideTwoAsset(activeLanguage)} type="video/mp4" />
        </video>
      ),
    },
    {
      title: t('readyToSpend.slideshows.welcome.three.title'),
      content: (
        <>
          <p className="body-l">
            {t('readyToSpend.slideshows.welcome.three.content')}
          </p>
          <div className="text-neutral-darker">
            <h3 className="mt-m title-m">
              {t('readyToSpend.slideshows.welcome.three.singleUse.name')}
            </h3>
            <p className="mt-xs">
              {t('readyToSpend.slideshows.welcome.three.singleUse.description')}
            </p>
            <h3 className="mt-m title-m">
              {t('readyToSpend.slideshows.welcome.three.recurring.name')}
            </h3>
            <p className="mt-xs">
              {t('readyToSpend.slideshows.welcome.three.recurring.description')}
            </p>
          </div>
        </>
      ),
      illustration: (
        <video loop muted autoPlay>
          <source src={getSlideThreeAsset(activeLanguage)} type="video/mp4" />
        </video>
      ),
    },
    {
      title: t('readyToSpend.slideshows.welcome.four.title'),
      content: (
        <>
          <p>{t('readyToSpend.slideshows.welcome.four.content')}</p>
          <p className="mt-s">
            {t('readyToSpend.slideshows.welcome.four.funFact')}
          </p>
        </>
      ),
      illustration: <img src={subscriptions} alt="" />,
    },
  ];

  useEffect(() => {
    if (!hasClosed) {
      setIsOpen(isTaskOngoing);
      if (isTaskOngoing) {
        track(AnalyticEventName.ONBOARDING_WALKTHROUGH_OPENED, {
          taskType: WELCOME_TASK_TYPE,
          totalSteps: slides.length,
          version: 0,
        });
      }
    }
  }, [isTaskOngoing]);

  const closeModal = async () => {
    setHasClosed(true);
    setIsOpen(false);
    if (userTask && userTask.status !== 'completed') {
      await completeOnboardingUserTaskMutation({
        endpointParams: { taskType: userTask.type },
      });
    }
  };

  const onClose = async ({ index }: { index: number }) => {
    await closeModal();
    track(AnalyticEventName.ONBOARDING_WALKTHROUGH_CLOSED, {
      lastStepSeen: index,
      taskType: WELCOME_TASK_TYPE,
      totalSteps: slides.length,
      version: 0,
    });
  };

  const onDone = async () => {
    await closeModal();
    track(AnalyticEventName.ONBOARDING_WALKTHROUGH_DONE, {
      taskType: WELCOME_TASK_TYPE,
      totalSteps: slides.length,
      version: 0,
    });
  };

  if (!userTask || userTask.status === 'completed') {
    return;
  }

  return (
    <ModalSlideshow
      isOpen={isOpen}
      slides={slides}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.next'),
        done: t('misc.gotIt'),
      }}
      onDone={onDone}
      onClose={onClose}
      onCancel={() => onClose({ index: 0 })}
    />
  );
};
