import { type Edge, MarkerType } from '@xyflow/react';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { type CustomNode } from '../node';

type AddNode = (sourceNodeId: string) => void;

type UseAddNodeProps = {
  setNodes: React.Dispatch<React.SetStateAction<CustomNode[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
  onEditClick: (nodeId: string) => void;
  onToggleExpand: (nodeId: string) => void;
};

export const useAddNode = ({
  setNodes,
  setEdges,
  onEditClick,
  onToggleExpand,
}: UseAddNodeProps): AddNode => {
  const addNode: AddNode = useCallback(
    (sourceNodeId: string) => {
      const newId = uuidv4();

      setNodes((previousNodes) => {
        const sourceNode = previousNodes.find(
          (node) => node.id === sourceNodeId,
        );
        if (!sourceNode) {
          throw new Error('Source node not found');
        }

        const newNodePosition = {
          x: sourceNode.position.x + 400,
          y: sourceNode.position.y,
        };

        const newNode: CustomNode = {
          id: newId,
          position: newNodePosition,
          type: 'baseNode',
          data: {
            nodeType: 'baseNode',
            onAddNodeClick: () => addNode(newId),
            onEditClick: () => onEditClick(newId),
            onToggleExpand: () => onToggleExpand(newId),
            isExpanded: false,
          },
        };

        return [...previousNodes, newNode];
      });

      setEdges((previousEdges) => {
        return [
          ...previousEdges,
          {
            id: `e${sourceNodeId}-${newId}`,
            source: sourceNodeId,
            target: newId,
            type: 'labelEdge',
            markerEnd: { type: MarkerType.ArrowClosed },
            data: {
              labelText: 'And if',
              labelVariant: 'primary',
            },
            sourceHandle: 'rightHandle',
          },
        ];
      });
    },
    [setNodes, setEdges],
  );

  return addNode;
};
