import { Button } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactElement, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type ApproverType } from 'src/core/modules/company/structure/approval-flows';

import { MemberTag } from '../MemberTag/MemberTag';

const MAX_MEMBERS_TO_DISPLAY = 3;

type Props = {
  members: {
    id: string;
    label: string;
    approverType: ApproverType;
    avatar?: string;
    tooltip?: ReactElement;
    icon?: ReactElement;
  }[];
  withIcons: boolean;
  hasNegativeMarginButton?: boolean;
};

export const MembersCheckList = ({
  members,
  withIcons,
  hasNegativeMarginButton = true,
}: Props) => {
  const { t } = useTranslation('global');

  const hasCollapseAction = members.length > MAX_MEMBERS_TO_DISPLAY;
  const [isListCollapsed, setIsListCollapsed] = useState(hasCollapseAction);
  const buttonClassName = cx('mt-xs', {
    '-mb-xs ': hasNegativeMarginButton,
  });
  return (
    <div className="flex w-full flex-col">
      <div className="relative mt-s flex max-w-full flex-col gap-s overflow-hidden">
        {isListCollapsed && (
          <div className="ApprovalList__collapsedBackground pointer-events-none" />
        )}
        {members.map((member, index) => {
          // using hidden is a way to avoid live loading avatar images of members when clicking on 'See more'
          const isHidden = isListCollapsed && index >= MAX_MEMBERS_TO_DISPLAY;
          if (!withIcons) {
            return (
              <div key={member.id}>
                <div className="flex items-center gap-xs">
                  <MemberTag
                    avatar={member.avatar}
                    name={member.label}
                    isHidden={isHidden}
                    tooltip={member?.tooltip}
                  />
                </div>
                {member.approverType === 'costCenterOwner' && (
                  <div className="mt-xxs text-neutral-dark body-s">
                    {t('teams.approvalFlows.costCenterOwner')}
                  </div>
                )}
                {member.approverType === 'reportingManager' && (
                  <div className="mt-xxs text-neutral-dark body-s">
                    {t('teams.approvalFlows.reportingManager')}
                  </div>
                )}
              </div>
            );
          }
          return (
            <div
              key={member.id}
              className={cx('ApprovalList__queueGrid', {
                hidden: isHidden,
              })}
            >
              <span className="relative mr-xs flex items-center">
                {
                  // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
                  member.icon ? member.icon : <span> </span>
                }
                {index !== members.length - 1 && (
                  <span className="ApprovalList__queueGrid__linkedLine" />
                )}
              </span>
              <MemberTag
                avatar={member.avatar}
                name={member.label}
                tooltip={member?.tooltip}
              />
              {member.approverType === 'costCenterOwner' && (
                <div className="ml-xs text-neutral-dark title-s">
                  {t('teams.approvalFlows.costCenterOwner')}
                </div>
              )}
              {member.approverType === 'reportingManager' && (
                <div className="ml-xs text-neutral-dark title-s">
                  {t('teams.approvalFlows.reportingManager')}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {hasCollapseAction &&
        (isListCollapsed ? (
          <Button
            className={buttonClassName}
            text={t('approval.progressBox.seeMore')}
            onClick={() => setIsListCollapsed(false)}
            iconName="caret-down"
            iconPosition="right"
            variant="secondary"
          />
        ) : (
          <Button
            className={buttonClassName}
            text={t('approval.progressBox.seeLess')}
            onClick={() => setIsListCollapsed(true)}
            iconName="caret-up"
            iconPosition="right"
            variant="secondary"
          />
        ))}
    </div>
  );
};
