/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, type Reducer } from '@reduxjs/toolkit';

import mfaActivation from 'common/components/ModalMFA/reducer';
import scaActivation from 'modules/StrongCustomerAuthentication/redux/reducer';
import { reducer as switchedSupervisedUserModalReducer } from 'modules/app/layout/components/SwitchedSupervisedUserModal/redux/reducer';
import { layoutReducer } from 'modules/app/layout/redux/reducer';
import { notificationsStackReducer } from 'modules/app/notifications';
import { reducer as bookkeepingExportReducer } from 'modules/bookkeep/export';
import accountingReducer from 'modules/bookkeep/settings/accounting-legacy/redux/reducer';
import { reducer as exportSettingsReducer } from 'modules/bookkeep/settings/export';
import exportsReducer from 'modules/bookkeep/settings/export-legacy/redux/reducer';
import { billingReducer } from 'modules/company/billing-legacy';
import { reducer as controlRulesReducer } from 'modules/company/members/components/ControlRules/redux/reducer';
import membersReducer from 'modules/company/members/redux/reducer';
import { notificationsReducer } from 'modules/company/notifications-legacy/redux/reducer';
import { reducer as approvalReducer } from 'modules/company/structure/approval-flows';
import { reducer as teamReducer } from 'modules/company/structure/teams';
import { reducer as walletReducer } from 'modules/company/wallet';
import { reducer as achReducer } from 'modules/company/wallet/ach';
import categoriesReducer from 'modules/custom-fields/redux/reducer';
import { reducer as controllerHomepageReducer } from 'modules/homepage/ControllerHomepage';
import { reducer as invoicesReducer } from 'modules/invoices';
import submitMyInvoiceReducer from 'modules/invoices/components/Invoices/reducer';
import paymentsReducer from 'modules/payments/redux/reducer';
import PerDiemModule from 'modules/per-diem';
import { reducers as physicalCardsReducers } from 'modules/physical-cards';
import { reducer as profileReducer } from 'modules/profile';
import { reducer as expenseClaimsReducer } from 'modules/reimbursements';
import RequestsModule from 'modules/requests';
import panelNewInvoiceReducer from 'src/core/modules/requests/redux/PanelNewInvoice/reducer';
import requestsReducer from 'src/core/modules/requests/redux/legacy/reducer';

import globalReducer from './global';
import sharedReducer from './shared';
import usersReducer from './users';

const rootReducer = combineReducers({
  global: globalReducer,
  shared: sharedReducer,
  users: usersReducer,
  billing: billingReducer,
  notifications: notificationsReducer,
  notificationsStack: notificationsStackReducer,
  accounting: accountingReducer as Reducer<any, any>,
  categories: categoriesReducer as Reducer<any, any>,
  payments: paymentsReducer,
  submitMyInvoice: submitMyInvoiceReducer as Reducer<any, any>,
  requests: requestsReducer as Reducer<any, any>,
  wallet: walletReducer,
  layout: layoutReducer,
  newInvoicePanel: panelNewInvoiceReducer,
  profile: profileReducer,
  cardsOrder: physicalCardsReducers.cardsOrder,
  cards: physicalCardsReducers.cards,
  card: physicalCardsReducers.card,
  members: membersReducer as Reducer<any, any>,
  exports: exportsReducer,
  switchedSupervisedUserModal: switchedSupervisedUserModalReducer,
  controllerHomepage: controllerHomepageReducer,
  group: teamReducer,
  mfaActivation,
  scaActivation,
  approval: approvalReducer,
  ach: achReducer,
  request: RequestsModule.reducer,
  expenseClaims: expenseClaimsReducer,
  invoices: invoicesReducer,
  perDiem: PerDiemModule.reducer,
  bookkeepingExport: bookkeepingExportReducer,
  control: controlRulesReducer,
  exportSettings: exportSettingsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
