import { IconButton, Icon, colors } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';

export type BaseNodeData = {
  nodeType: 'baseNode';
  onAddNodeClick: () => void;
  onEditClick: () => void;
  onToggleExpand: () => void;
  isExpanded: boolean;
};

type Props = NodeProps & {
  data: BaseNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const BaseNode = ({ data }: Props) => {
  return (
    <div className="flex items-center">
      <div className="w-[360px] rounded border border-solid border-neutral-lightest bg-white p-s">
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-xs">
              <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-primary-lighter bg-primary-lightest">
                <Icon color={colors.primary} name="airplane" size="s" />
              </div>
              <div className="text-complementary body-s">Spend type is</div>
            </div>
            <div className="mt-xs text-complementary title-l">
              Expense claims, Invoices
            </div>
          </div>
          <div className="flex">
            <IconButton
              aria-label=""
              iconColor={colors.complementary}
              onClick={data.onToggleExpand}
              iconName={data.isExpanded ? 'caret-up' : 'caret-down'}
            />
            <IconButton
              aria-label=""
              iconColor={colors.complementary}
              onClick={data.onEditClick}
              iconName="pen"
            />
          </div>
        </div>
        {data.isExpanded && (
          <div className="box mt-s p-m">
            <div className="flex items-center gap-xs">
              <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-neutral-light bg-neutral-lightest">
                <Icon color={colors.neutralDark} name="magic-wand" size="s" />
              </div>
              <div className="text-neutral-darker title-s">
                Approval is required from
              </div>
            </div>
          </div>
        )}
      </div>
      <IconButton
        variant="borderless"
        onClick={data.onAddNodeClick}
        iconName="plus-circle"
        aria-label="plus"
      />
      <Handle
        style={handleStyle}
        type="target"
        id="leftHandle"
        position={Position.Left}
      />
      <Handle
        style={handleStyle}
        type="source"
        id="rightHandle"
        position={Position.Right}
      />
    </div>
  );
};
