import { Button, Callout, Input } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { OrganisationReportingEntityListSearch } from './OrganisationReportingEntityListSearch';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import { type OrganisationReportingEntity } from '../../../types';
import { OrganisationReportingEntityStatistics } from '../OrganisationReportingEntityStatistics';

export const OrganisationReportingEntityList = ({
  features,
  entities,
  onDataLoaded,
}: {
  features: OrganisationFeatures;
  entities: OrganisationReportingEntity[];
  onDataLoaded: () => void;
}) => {
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();

  const [isSearchInputDisabled, setIsSearchInputDisabled] = useState(true);
  const [queryStates, setQueryStates] = useState<
    { entityId: string; status: QueryState['status'] }[]
  >([]);

  const [dataLoadingFailureCount, setDataLoadingFailureCount] = useState<
    number | null
  >(null);

  const entityIds = entities.map(({ id }) => id);
  useEffect(() => {
    const hasAllQueriesSucceeded = entityIds.every((id) =>
      queryStates.find(({ entityId }) => id === entityId),
    );

    if (hasAllQueriesSucceeded) {
      setIsSearchInputDisabled(false);
      onDataLoaded();
      return () => {};
    }
  }, [queryStates]);

  const onEntityDataLoaded = (
    entityId: string,
    status: QueryState['status'],
  ) => {
    setQueryStates((previousQueryStates) => [
      ...previousQueryStates,
      { entityId, status },
    ]);

    if (status === 'error') {
      setDataLoadingFailureCount((previousCount) => (previousCount ?? 0) + 1);
    }
  };

  const [isRefreshingFailedEntities, setIsRefreshingFailedEntities] =
    useState(false);
  const refreshFailedEntities = async () => {
    setIsRefreshingFailedEntities(true);

    const failedEntities = queryStates
      .filter(({ status }) => status === 'error')
      .map(({ entityId }) => entityId);

    try {
      await Promise.all(
        failedEntities.map((entityId) =>
          queryClient.invalidateQueries(['organisation-reporting', entityId]),
        ),
      );
    } finally {
      setIsRefreshingFailedEntities(false);
    }
  };

  return (
    <OrganisationReportingEntityListSearch
      entities={entities}
      searchableFields={['id', 'name']}
      sortFn={(a, b) => a.name.localeCompare(b.name)}
    >
      {({ filteredEntities, search, setSearch }) => (
        <div className="flex flex-col gap-s">
          <div className="flex flex-row justify-between gap-l">
            <h2 className="title-xl">
              {t('organisation.reporting.page.entities.title')}
            </h2>

            <div>
              <Input
                isDisabled={isSearchInputDisabled}
                className="min-w-[312px]"
                placeholder={t(
                  'organisation.reporting.page.entities.searchPlaceholder',
                )}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
              />
            </div>
          </div>

          {dataLoadingFailureCount !== null && dataLoadingFailureCount > 0 && (
            <Callout
              variant="alert"
              title={t(
                'organisation.reporting.page.entities.dataFailureCallout.title',
              )}
              iconName="failure"
            >
              <div className="flex flex-col items-start gap-s">
                <p className="text-complementary body-m">
                  {t(
                    'organisation.reporting.page.entities.dataFailureCallout.description',
                    {
                      count: dataLoadingFailureCount,
                      total: entities.length,
                    },
                  )}
                </p>
                <Button
                  variant="alert"
                  isDisabled={isRefreshingFailedEntities}
                  text={t(
                    'organisation.reporting.page.entities.dataFailureCallout.cta',
                  )}
                  className="w-[157px]"
                  iconName="rotate"
                  iconPosition="left"
                  onClick={refreshFailedEntities}
                />
              </div>
            </Callout>
          )}

          {filteredEntities.map((entity) => (
            <OrganisationReportingEntityStatistics
              key={entity.id}
              entity={entity}
              features={features}
              onDataLoaded={(entityId, status) =>
                onEntityDataLoaded(entityId, status)
              }
            />
          ))}
        </div>
      )}
    </OrganisationReportingEntityListSearch>
  );
};
