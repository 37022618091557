import isValid from 'date-fns/isValid';
import { equal } from 'ezmoney';
import * as F from 'formik';
import * as yup from 'yup';

import {
  PayableRequestValidation,
  Schemas,
} from '@finance-review/models/payable';
import { filterOutUndefinedProperties } from 'common/utils/filterOutUndefinedProperties';

import * as DraftInvoiceRequest from './draftInvoiceRequest';
import * as DraftInvoiceRequestErrors from './draftInvoiceRequestErrors';
import * as DraftPaymentSchedule from './draftPaymentSchedule';

export interface ValidationContext
  extends PayableRequestValidation.ValidationContext {
  isSupplierValidated?: boolean | undefined;
  isEligibleToSepaPayments: boolean;
}

export interface Errors extends PayableRequestValidation.Errors {
  amount?:
    | DraftInvoiceRequestErrors.RequiredError
    | DraftInvoiceRequestErrors.NonPositiveAmountError;
  emissionDate?:
    | DraftInvoiceRequestErrors.RequiredError
    | DraftInvoiceRequestErrors.EmissionDateInTheFuture;
  dueDate?:
    | DraftInvoiceRequestErrors.RequiredError
    | DraftInvoiceRequestErrors.DateLaterThanMaximumError
    | DraftInvoiceRequestErrors.DueDateBeforeEmissionDateError;
  invoiceNumber?: DraftInvoiceRequestErrors.RequiredError;
  paymentDate?: DraftInvoiceRequestErrors.RequiredError;
  paymentReference?:
    | DraftInvoiceRequestErrors.PaymentReferenceMoreThanMaxLengthError
    | DraftInvoiceRequestErrors.PaymentReferenceInvalidFormatError;
  paymentSchedule?:
    | DraftInvoiceRequestErrors.PaymentScheduleNotMatchingAmountError
    | {
        date?: DraftInvoiceRequestErrors.RequiredError;
        amount?:
          | DraftInvoiceRequestErrors.RequiredError
          | DraftInvoiceRequestErrors.NonPositiveAmountError;
      }[];
  supplierId?:
    | DraftInvoiceRequestErrors.RequiredError
    | DraftInvoiceRequestErrors.SupplierNotValidatedError;
}

export type CoreInvoiceFormikErrors =
  PayableRequestValidation.ToCorePayableErrors<DraftInvoiceRequest.Entity>;
export type FormikErrors =
  PayableRequestValidation.ToPayableErrors<DraftInvoiceRequest.Entity>;

const paymentReferenceRegExp = new RegExp(/^[a-zA-Z0-9/\-?:().,'+\s]*$/);

export const validate = (
  draftInvoiceRequest: DraftInvoiceRequest.Entity,
  validationContext: ValidationContext,
): CoreInvoiceFormikErrors => {
  const payableErrors = PayableRequestValidation.validate(
    draftInvoiceRequest,
    validationContext,
  );

  try {
    F.validateYupSchema(draftInvoiceRequest, draftInvoiceRequestSchema, true, {
      ...validationContext,
      draftInvoiceRequest,
    });
    return payableErrors;
  } catch (e) {
    return {
      ...payableErrors,
      ...F.yupToFormErrors<DraftInvoiceRequest.Entity>(e),
    } as CoreInvoiceFormikErrors;
  }
};

export const fromFormikErrors = (formikErrors: FormikErrors): Errors =>
  filterOutUndefinedProperties({
    ...PayableRequestValidation.fromFormikErrors(formikErrors),
    amount: DraftInvoiceRequestErrors.parseAmountError(formikErrors.amount),
    dueDate: DraftInvoiceRequestErrors.parseFromFormikError<
      | DraftInvoiceRequestErrors.RequiredError
      | DraftInvoiceRequestErrors.DateLaterThanMaximumError
      | DraftInvoiceRequestErrors.DueDateBeforeEmissionDateError
    >(
      [
        DraftInvoiceRequestErrors.requiredError,
        DraftInvoiceRequestErrors.dateLaterThanMaximumError,
        DraftInvoiceRequestErrors.dueDateBeforeEmissionDateError,
      ],
      formikErrors.dueDate,
      DraftInvoiceRequestErrors.requiredError,
    ),
    emissionDate: DraftInvoiceRequestErrors.parseFromFormikError<
      | DraftInvoiceRequestErrors.RequiredError
      | DraftInvoiceRequestErrors.DateOlderThanMinimumError
      | DraftInvoiceRequestErrors.EmissionDateInTheFuture
    >(
      [
        DraftInvoiceRequestErrors.requiredError,
        DraftInvoiceRequestErrors.dateOlderThanMinimumError,
        DraftInvoiceRequestErrors.emissionDateInTheFuture,
      ],
      formikErrors.emissionDate,
      DraftInvoiceRequestErrors.requiredError,
    ),
    invoiceNumber: DraftInvoiceRequestErrors.parseToRequiredError(
      formikErrors.invoiceNumber,
    ),
    paymentDate: DraftInvoiceRequestErrors.parseToRequiredError(
      formikErrors.paymentDate,
    ),
    paymentReference: DraftInvoiceRequestErrors.parsePaymentReferenceError(
      formikErrors.paymentReference,
    ),
    paymentSchedule: DraftInvoiceRequestErrors.parsePaymentScheduleError(
      formikErrors.paymentSchedule as F.FormikErrors<DraftPaymentSchedule.Entity>,
    ),
    supplierId: DraftInvoiceRequestErrors.parseSupplierError(
      formikErrors.supplierId,
    ),
  });

const getIsSupplierValidated = (
  { supplierName, supplierId }: DraftInvoiceRequest.Entity,
  { isSupplierValidated }: ValidationContext,
): boolean => {
  // new supplier that does not exist in the back-end
  if (supplierName && !supplierId) {
    return false;
  }

  // in case of undefined, it means we don't know if it was validated. We leave it to the back-end to check
  return isSupplierValidated ?? true;
};

const isPaymentScheduleAmountValid = (
  draftInvoiceRequest: DraftInvoiceRequest.Entity,
): boolean => {
  const totalAmount = DraftInvoiceRequest.getTotalAmount(draftInvoiceRequest);

  // we compare the payment schedule to the total amount of the invoice (including credit note)
  // if the invoice totalAmount is not defined, we can't validate the payment schedule so we return true
  // if the invoice totalAmount is defined and matches the payment schedule amount, we return true
  return (
    totalAmount === undefined ||
    equal(
      DraftPaymentSchedule.computeTotal(
        draftInvoiceRequest.paymentSchedule,
        draftInvoiceRequest.currency,
      ),
      totalAmount,
    )
  );
};

const amountFieldSchema = Schemas.amount.moreThan(
  0,
  DraftInvoiceRequestErrors.nonPositiveAmountError,
);

const draftScheduledPaymentSchema = yup.object().shape({
  id: Schemas.optionalText,
  amount: amountFieldSchema,
  date: Schemas.date,
});

const draftPaymentScheduleSchema = yup
  .array()
  .of(draftScheduledPaymentSchema)
  .min(1)
  .test(
    'amountMatch',
    DraftInvoiceRequestErrors.paymentScheduleNotMatchingAmountError,
    (value, context) => {
      if (!value) {
        return false;
      }
      return isPaymentScheduleAmountValid(context.parent);
    },
  );

const supplierSchema = yup
  .string()
  .test(
    'supplierInvalid',
    DraftInvoiceRequestErrors.supplierNotValidatedError,
    (_, context) =>
      getIsSupplierValidated(
        context.parent,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (context as yup.TestContext<ValidationContext>).options.context!,
      ),
  )
  .test('supplierRequired', DraftInvoiceRequestErrors.requiredError, (value) =>
    Boolean(value),
  );

const paymentReferenceSchema = Schemas.optionalText
  .max(
    DraftInvoiceRequest.paymentReferenceMaxLength,
    DraftInvoiceRequestErrors.paymentReferenceMoreThanMaxLengthError,
  )
  .matches(
    paymentReferenceRegExp,
    DraftInvoiceRequestErrors.paymentReferenceInvalidFormatError,
  );

const draftInvoiceRequestSchema = yup.object().shape({
  amount: amountFieldSchema,
  dueDate: Schemas.date.when('emissionDate', ([emissionDate], schema) =>
    isValid(emissionDate)
      ? schema.min(
          emissionDate,
          DraftInvoiceRequestErrors.dueDateBeforeEmissionDateError,
        )
      : schema,
  ),
  emissionDate: yup.lazy(() =>
    Schemas.date.max(
      new Date(),
      DraftInvoiceRequestErrors.emissionDateInTheFuture,
    ),
  ),
  invoiceNumber: Schemas.text,
  paymentDate: yup.date().when('paymentMethod', {
    is: 'directDebit',
    then: () => Schemas.date,
    otherwise: () => Schemas.optionalDate,
  }),
  paymentReference: yup.string().when('$isEligibleToSepaPayments', {
    is: true,
    then: () => paymentReferenceSchema,
    otherwise: () => Schemas.optionalText,
  }),
  paymentSchedule: yup.mixed().when('$draftInvoiceRequest', {
    is: (draftInvoiceRequest: DraftInvoiceRequest.Entity) =>
      DraftInvoiceRequest.hasPaymentsToSchedule(draftInvoiceRequest),
    then: () => draftPaymentScheduleSchema,
    otherwise: () => yup.mixed(),
  }),
  supplierId: supplierSchema,
});
