import { FormField, TextInput } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { type FormikProps } from 'formik';

import { PhoneNumberInput } from 'common/components/PhoneNumberInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useIsUserManagedByKombo } from 'src/core/modules/integrations/hooks';

import { MemberBankInfoForm } from './MemberBankInfoForm';
import { UserManagedByKomboCallout } from './UserManagedByKomboCallout';
import { useCanEditUserBankInfo } from '../../hooks/useCanEditUserBankInfo';
import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { useHasCompanyUserBankInfos } from '../../hooks/useHasCompanyUserBankInfos';
import { getMemberPhoneNumber, type Member } from '../../models/member';
import { type MemberEditFormikValues } from '../MemberEditModal/validate';
import './MemberEditTabInformation.css';
import { MemberManagerAutoCompleteForm } from '../MemberManagerAutoComplete/MemberManagerAutoCompleteForm';

type Props = {
  user: Member;
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabInformation = ({ user, formikProps }: Props) => {
  const { t } = useTranslation('global');

  const hasReportingManagerFeature = useFeature(
    FEATURES.TMP_CONMAN_REPORTING_MANAGERS,
  );

  const isManagedByKombo = useIsUserManagedByKombo({ userId: user.id });
  const getCompanyManagedByKombo =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const canEditUserBankInfo = useCanEditUserBankInfo(user);
  const hasCompanyUserBankInfos = useHasCompanyUserBankInfos();
  const companyHrFeatures =
    unwrapQuery(getCompanyManagedByKombo)?.featureSet ?? [];
  const hasBankAccountInfoInHrTool = companyHrFeatures.includes(
    'includesBankDetails',
  );
  const hasReportingManagerSyncEnabled = companyHrFeatures.includes(
    'includesReportingManagers',
  );

  const isManagerEditDisabled =
    isManagedByKombo && hasReportingManagerSyncEnabled && !!user.manager;
  const renderNameRow = () => {
    return (
      <div className="MemberEditTabInformation__row">
        <FormField
          label={t('profile.firstName')}
          alertMessage={
            formikProps.errors.firstName
              ? t('members.editModal.firstNameError')
              : undefined
          }
        >
          <TextInput
            value={formikProps.values.firstName}
            onChange={(e) => {
              formikProps.setFieldValue('firstName', e.target.value);
            }}
            isDisabled={isManagedByKombo}
          />
        </FormField>
        <FormField
          label={t('profile.lastName')}
          alertMessage={
            formikProps.errors.lastName
              ? t('members.editModal.lastNameError')
              : undefined
          }
        >
          <TextInput
            value={formikProps.values.lastName}
            onChange={(e) => {
              formikProps.setFieldValue('lastName', e.target.value);
            }}
            isDisabled={isManagedByKombo}
          />
        </FormField>
      </div>
    );
  };

  const renderContactRow = () => {
    return (
      <div
        className={cx('MemberEditTabInformation__row', {
          'MemberEditTabInformation__row--full': user.pending,
        })}
      >
        {!user.pending && (
          <FormField label={t('misc.mobilePhoneNumber')}>
            <PhoneNumberInput value={getMemberPhoneNumber(user)} isDisabled />
          </FormField>
        )}
        <FormField label={t('misc.emailAddress')}>
          <TextInput value={user.email} isDisabled onChange={() => {}} />
        </FormField>
      </div>
    );
  };

  const renderManagerRow = () => {
    return (
      hasReportingManagerFeature && (
        <div className="mt-m text-left">
          <FormField label={t('members.detailsModal.manager')}>
            <MemberManagerAutoCompleteForm
              currentMember={user}
              isDisabled={isManagerEditDisabled}
              formikProps={formikProps}
            />
          </FormField>
        </div>
      )
    );
  };

  const renderBankInfoBlock = () => {
    return (
      <div className="MemberEditTabInformation__bank-info-block">
        <MemberBankInfoForm
          requiredInfoTypesForEmployeeBankAddress={
            user.requiredInfoTypesForEmployeeBankAddress
          }
          isEditingDisabled={!canEditUserBankInfo}
          formikProps={formikProps}
        />
      </div>
    );
  };

  return (
    <div className="MemberEditTabInformation">
      <UserManagedByKomboCallout
        userId={user.id}
        hasBankAccountInfoInHrTool={hasBankAccountInfoInHrTool}
      />
      {!user.pending && renderNameRow()}
      {renderContactRow()}
      {renderManagerRow()}
      {hasCompanyUserBankInfos && renderBankInfoBlock()}
    </div>
  );
};
