import { FormField, Select } from '@dev-spendesk/grapes';

import { ApprovalRulesContainer } from 'src/core/modules/company/structure/approval-flows';

import { FloatingPanel } from '../FloatingPanel/FloatingPanel';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedNodeId: string | undefined;
};

export const NodeSettingsPanel = ({
  isOpen,
  onClose,
  selectedNodeId,
}: Props) => {
  // eslint-disable-next-line no-console
  console.log(selectedNodeId);

  return (
    <FloatingPanel isOpen={isOpen} onClose={onClose}>
      <FormField className="text-left" label="When">
        <Select
          name="criteria"
          options={[
            { key: 'spendType', label: 'Spend Type' },
            { key: 'costCenter', label: 'Cost Center' },
          ]}
          value={{ key: 'spendType', label: 'Spend Type' }}
          onSelect={() => null}
          fit="parent"
        />
      </FormField>
      <FormField className="mt-s text-left" label="Is">
        <Select
          name="criteriaValue"
          options={[
            { key: 'expenseClaim', label: 'Expense Claim' },
            { key: 'invoice', label: 'Invoice Request' },
            { key: 'mileage', label: 'Mileage Request' },
            { key: 'perDiem', label: 'Per Diem Request' },
            { key: 'virtualCard', label: 'Virtual Card Request' },
          ]}
          value={{ key: 'expenseClaim', label: 'Expense Claim' }}
          onSelect={() => null}
          fit="parent"
        />
      </FormField>
      <div>
        <ApprovalRulesContainer
          rules={approvalRulesMocked}
          members={membersMocked}
          costCenterOwnerId={undefined}
          onChange={() => null}
        />
      </div>
    </FloatingPanel>
  );
};

const approvalRulesMocked = [
  {
    isAoRule: false,
    upTo: { value: null, currency: 'EUR' as const },
    steps: [
      {
        rights: [
          { approverId: 'Vvsn2aBtHoQOk4', approverType: 'user' as const },
        ],
      },
    ],
    from: { value: 0, currency: 'EUR' as const },
    id: 'd9f4f7ab-1330-4120-a496-61c4928f20cb',
    index: 0,
    isLastRule: true,
  },
];

const membersMocked = [
  {
    id: 'Vvsn2aBtHoQOk4',
    fullname: 'Rosie Will',
    email: 'Rosie.Will31-8772121735@gmail.com',
    avatar: 'https://avatars.githubusercontent.com/u/33935517',
    isPending: false,
    isAccountOwner: false,
  },
];
