import { Button, Icon, Panel, SkeletonText } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { type LocationDescriptor } from 'history';
import { type TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { type QueryState } from 'src/core/api/queryState';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { getCountryFromCode } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { EntityBreakdownBlock } from './EntityBreakdownBlock';
import { EntityGenericStatisticsBlock } from './EntityGenericStatisticsBlock';
import {
  OrganisationReportingEntityStatisticsCallout,
  type OrganisationReportingEntityStatusForCallout,
} from './OrganisationReportingEntityStatisticsCallout/OrganisationReportingEntityStatisticsCallout';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import { useOrganisationReportingEntityDetails } from '../../../hooks/useOrganisationReportingEntityDetails';
import {
  type OrganisationReportingStatistics,
  type OrganisationReportingEntity,
  type OrganisationReportingEntityDetails,
} from '../../../types';

type CountryCode = Parameters<typeof getCountryFromCode>[0];

type EntityStatisticDefinitions = Record<
  keyof OrganisationReportingStatistics,
  {
    isAvailable: boolean;
    title: string;
    link: LocationDescriptor;
    label: string | null;
    trackingEventName?: AnalyticEventName;
  }
>;

type Props = {
  features: OrganisationFeatures;
  entity: OrganisationReportingEntity;
  onDataLoaded: (entityId: string, status: QueryState['status']) => void;
};

export const OrganisationReportingEntityStatistics = ({
  entity,
  features,
  onDataLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  const entityDetailsQueryState = useOrganisationReportingEntityDetails(
    entity,
    features,
  );

  useEffect(() => {
    if (['success', 'error'].includes(entityDetailsQueryState.status)) {
      onDataLoaded(entity.id, entityDetailsQueryState.status);
      return () => {};
    }
  }, [entityDetailsQueryState.status]);

  const entityStatistics: EntityStatisticDefinitions = {
    requestsToApprove: {
      isAvailable: features.requestsToApprove,
      title: t('organisation.reporting.page.entities.entity.requestsToApprove'),
      link: routeFor(routes.REQUESTS.path, {
        company: entity.id,
        type: 'to-approve',
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_REQUESTS_TO_APPROVE_BUTTON_CLICKED,
    },
    invoicesToPay: {
      isAvailable: features.invoicesToPay,
      title: t('organisation.reporting.page.entities.entity.invoicesToPay'),
      link: routeFor(routes.INVOICES_PAY.path, {
        company: entity.id,
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_INVOICES_TO_PAY_BUTTON_CLICKED,
    },
    lateReceipts: {
      isAvailable: features.lateReceipts,
      title: t('organisation.reporting.page.entities.entity.lateReceipts'),
      link: `${routeFor(routes.PAYMENTS_ALL.path, {
        company: entity.id,
      })}?completionDeadline=late`,
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_LATE_RECEIPTS_BUTTON_CLICKED,
    },
    payablesToReview: {
      isAvailable: features.payablesToReview,
      title: t('organisation.reporting.page.entities.entity.payablesToReview'),
      link: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: entity.id,
      }),
      label: null,
      trackingEventName:
        AnalyticEventName.ORGANISATION_REPORTING_PAYABLES_TO_REVIEW_BUTTON_CLICKED,
    },
  };

  const placeholder = '-';
  const setStatisticsIntoDefinitions = (
    entityDetails: OrganisationReportingEntityDetails,
  ) => {
    entityStatistics.requestsToApprove.label = String(
      entityDetails.requestsToApprove ?? placeholder,
    );
    entityStatistics.invoicesToPay.label = String(
      entityDetails.invoicesToPay ?? placeholder,
    );
    entityStatistics.lateReceipts.label = String(
      entityDetails.lateReceipts ?? placeholder,
    );
    entityStatistics.payablesToReview.label = String(
      entityDetails.payablesToReview ?? placeholder,
    );
  };

  return (
    <QuerySuspense
      queryState={entityDetailsQueryState}
      loading={
        <Panel className="box-border w-full">
          <div className="flex gap-l">
            <div className="flex flex-auto flex-wrap gap-l">
              <div className="flex-[3]">
                <EntityGenericStatisticsBlock
                  title={getBranchTypeTitle(entity, t)}
                  contents={[
                    <div key="name" className="font-medium">
                      <SkeletonText />
                    </div>,
                    <div key="country">
                      <SkeletonText />
                    </div>,
                  ]}
                  stack
                />
              </div>
              {features.wallet && (
                <div className="flex-[3]">
                  <EntityGenericStatisticsBlock
                    stack
                    title={t(
                      'organisation.reporting.page.entities.entity.walletBalance',
                    )}
                    contents={[
                      <div key="walletBalance" className="font-medium">
                        <SkeletonText />
                      </div>,
                      <div key="wallBalanceAddFunds">
                        <SkeletonText />
                      </div>,
                    ]}
                  />
                </div>
              )}
              {features.wallet && (
                <div className="flex-[10]">
                  <SkeletonText size="l" />
                  <div className="flex flex-row justify-between gap-xs pt-s">
                    {[...Array(3).keys()].map((key) => (
                      <div key={`skeleton-${key}`} className="flex-1">
                        <SkeletonText />
                        <SkeletonText />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {Object.entries(entityStatistics)
                // FIXME Perf issue: Filtering in DOM
                .filter(([, { isAvailable }]) => isAvailable)
                .map(([key, { title }]) => (
                  <div key={key} className="flex-[2]">
                    <EntityGenericStatisticsBlock
                      title={title}
                      contents={[<SkeletonText key="skeleton" />]}
                    />
                  </div>
                ))}
            </div>
            <div className="flex-0 w-fit self-center">
              <Button
                isDisabled
                className="whitespace-nowrap"
                variant="secondary"
                text={t('organisation.reporting.page.entities.entity.openLink')}
                iconName="external"
                iconPosition="left"
              />
            </div>
          </div>
        </Panel>
      }
      fallback={() => null}
    >
      {(entityDetails) => {
        // TODO: Remove this when we want to display churned entities
        // See https://spendesk.atlassian.net/browse/ECO-395
        if (entityDetails.hasChurned) {
          return null;
        }

        const isAvailableFundsLow = entityDetails.breakdown.trend === 'warning';

        setStatisticsIntoDefinitions(entityDetails);
        const entityStatus = getEntityStatus(entityDetails);

        const statusesForInactiveState: OrganisationReportingEntityStatusForCallout[] =
          ['kycInProgress', 'awaitingKycApproval'] as const;
        const isInactive =
          entityStatus && statusesForInactiveState.includes(entityStatus);
        const isOpenEntityButtonDisabled =
          entityStatus === 'kycInProgress' ? false : isInactive;

        return (
          <div className="relative flex flex-col content-stretch">
            <Panel
              className={classnames(
                'z-[8] box-border w-full',
                entityStatus === 'kycInProgress' && 'border-warning-lighter',
              )}
            >
              <div className="flex gap-l">
                <div className="flex flex-auto flex-wrap gap-l">
                  <div className="flex-[3]">
                    <EntityGenericStatisticsBlock
                      title={getBranchTypeTitle(entityDetails, t)}
                      contents={[
                        <div key="name" className="font-medium">
                          {entityDetails.name}
                        </div>,
                        <div key="country">
                          {getPrettyCountryFromCode(entityDetails.country, t)}
                        </div>,
                      ]}
                      stack
                    />
                  </div>
                  {features.wallet && (
                    <div className="flex-[3]">
                      <EntityGenericStatisticsBlock
                        stack
                        title={t(
                          'organisation.reporting.page.entities.entity.walletBalance',
                        )}
                        contents={[
                          <div key="walletBalance" className="font-medium">
                            {formatMonetaryValue({
                              amount: entityDetails.walletBalance,
                              currency: entityDetails.currency,
                              precision: 2,
                            })}
                          </div>,
                          <div key="wallBalanceAddFunds">
                            <Link
                              to={routeFor(routes.COMPANY_BANK_FUNDING.path, {
                                company: entityDetails.id,
                              })}
                              target="_blank"
                              className={classnames(
                                'flex items-center gap-xxs leading-[16px] text-complementary title-s',
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                { 'text-warning': isAvailableFundsLow },
                              )}
                              onClick={() =>
                                track(
                                  AnalyticEventName.ORGANISATION_REPORTING_ADD_FUNDS_BUTTON_CLICKED,
                                )
                              }
                            >
                              {t(
                                'organisation.reporting.page.entities.entity.walletBalanceAddFunds',
                              )}
                              <Icon name="caret-right" />
                            </Link>
                          </div>,
                        ].filter((component) => component)}
                        isInactive={isInactive}
                      />
                    </div>
                  )}
                  {features.wallet && (
                    <div className="flex-[10]">
                      <EntityBreakdownBlock
                        currency={entityDetails.currency}
                        breakdown={entityDetails.breakdown}
                        isInactive={isInactive && entityStatus !== 'churning'}
                      />
                    </div>
                  )}
                  {Object.entries(entityStatistics)
                    // FIXME Perf issue: Filtering in DOM
                    .filter(([, { isAvailable }]) => isAvailable)
                    .map(([key, { title, link, label, trackingEventName }]) => (
                      <div key={key} className="flex-[2]">
                        <EntityGenericStatisticsBlock
                          title={title}
                          contents={[
                            isInactive || entityStatus === 'churning' ? (
                              <p className="items-center justify-center text-center leading-[16px] text-neutral-dark title-m">
                                -
                              </p>
                            ) : (
                              label && (
                                <Button
                                  component="a"
                                  href={link.toString()}
                                  target="_blank"
                                  variant="ghost"
                                  className={classnames(
                                    label === '0' && 'text-neutral-dark',
                                  )}
                                  text={label}
                                  onClick={
                                    trackingEventName &&
                                    (() =>
                                      track(trackingEventName, {
                                        value: label,
                                      }))
                                  }
                                  iconName="caret-right"
                                  iconPosition="right"
                                />
                              )
                            ),
                          ]}
                        />
                      </div>
                    ))}
                </div>
                <div className="flex-0 w-fit self-center">
                  <Button
                    isDisabled={isOpenEntityButtonDisabled}
                    component="a"
                    href={routeFor(routes.HOMEPAGE.path, {
                      company: entityDetails.id,
                    })}
                    className="whitespace-nowrap"
                    target="_blank"
                    variant="secondary"
                    text={t(
                      'organisation.reporting.page.entities.entity.openLink',
                    )}
                    onClick={() =>
                      track(
                        AnalyticEventName.ORGANISATION_REPORTING_OPEN_ENTITY_BUTTON_CLICKED,
                      )
                    }
                    iconName="external"
                    iconPosition="left"
                  />
                </div>
              </div>
            </Panel>
            {entityStatus && (
              <OrganisationReportingEntityStatisticsCallout
                state={entityStatus}
                entityId={entityDetails.id}
              />
            )}
          </div>
        );
      }}
    </QuerySuspense>
  );
};

const getBranchTypeTitle = (
  { type }: OrganisationReportingEntity,
  t: TFunction<'global', undefined>,
): string => {
  switch (type) {
    case 'branch_currency':
      return t(
        'organisation.reporting.page.entities.entity.type.branchCurrency',
      );
    case 'branch_expense_entity':
      return t(
        'organisation.reporting.page.entities.entity.type.expenseEntity',
      );
    default:
      return t('organisation.reporting.page.entities.entity.type.legalEntity');
  }
};

const getPrettyCountryFromCode = (
  countryCode: CountryCode,
  t: TFunction<'global', undefined>,
) => {
  const country = getCountryFromCode(countryCode);

  if (country) {
    return t(country.translationKey);
  }

  return countryCode;
};

const getEntityStatus = (
  entityDetails: OrganisationReportingEntityDetails,
): OrganisationReportingEntityStatusForCallout | undefined => {
  if (entityDetails.isKycInProgress) {
    return 'kycInProgress';
  }
  if (entityDetails.isKycAwaitingApproval) {
    return 'awaitingKycApproval';
  }
  if (entityDetails.isChurning) {
    return 'churning';
  }
  if (entityDetails.hasChurned) {
    return entityDetails.walletBalance > 0
      ? 'churnedWithRemainingFunds'
      : 'churned';
  }

  return undefined;
};
