import { useFormik, type FormikErrors } from 'formik';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { usePrevious, useAsync } from 'react-use';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { ActiveAccountingIntegrationApi } from 'modules/bookkeep/hooks';
import { useDesignatedCustomFieldQuery } from 'modules/custom-fields/hooks/useDesignatedCustomFieldQuery';
import { companyAPI } from 'src/core/api/axios';
import { type AppState } from 'src/core/reducers';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import { TemplateEditorForm } from './TemplateEditorForm';
import AccountingSoftwaresValues from './accountingSoftwareValues.json';
import { type FormValues } from './formValues';
import { useIsAnalyticalSplitActivated } from '../../../../apis/analytical-split/useAnalyticalSplitStatus';
import { type CustomField } from '../../customField';
import * as actions from '../../redux/actions';
import { toApiTemplate } from '../../redux/reshapers';
import * as selectors from '../../redux/selectors';
import * as thunks from '../../redux/thunks';
import {
  type Template,
  type Column,
  type AvailableColumn,
  getCompositePatternPartOptions,
  searchAvailableColumns,
  type PreviewRow,
} from '../../template';

type Props = {
  companyId: string;
  templateId?: string;
  template?: Template;
  customFields: CustomField[];
  availableTemplateColumns: AvailableColumn[];
  hasLoadedTemplate: boolean;
  onCancel: () => void;
  onSave: () => void;
  fetchTemplate: (
    id: string | 'default',
    isTranslated: boolean,
  ) => Promise<void>;
  saveTemplate: (values: FormValues, templateId?: string) => Promise<void>;
  resetOpenedTemplate: () => void;
};

const TemplateEditorFormManager = ({
  companyId,
  templateId,
  template,
  customFields,
  availableTemplateColumns,
  hasLoadedTemplate,
  onCancel,
  onSave,
  fetchTemplate,
  saveTemplate,
  resetOpenedTemplate,
}: Props) => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');
  const [rows, setRows] = useState<PreviewRow[]>([]);
  const [isComputingRows, setIsComputingRows] = useState<boolean>(false);
  const isTemplateEditionMode = templateId !== undefined;
  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();
  const othersLabel = t('exports.others');
  const availableAccountingSoftwares =
    getAvailableAccountingSoftwares(othersLabel);

  const activeAccountingIntegration =
    ActiveAccountingIntegrationApi.useActiveAccountingIntegration();

  const isDoubleOrSingleEntry =
    activeAccountingIntegration.status === 'success' &&
    (activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccounting' ||
      activeAccountingIntegration.data.activeAccountingIntegration ===
        'SpendeskAccountingSingleEntry');

  const expenseCategoryCustomFieldId =
    designatedCustomFieldQueryState.status === 'success'
      ? designatedCustomFieldQueryState.data.customFieldId
      : undefined;

  const initialAccountingSoftware =
    (template?.accountingSoftware === 'Others'
      ? othersLabel
      : template?.accountingSoftware) ?? '';

  const formikProps = useFormik<FormValues>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      dateFormat: template?.dateFormat ?? ['DD', 'MM', 'YYYY'],
      dateDelimiter: template?.dateDelimiter ?? '-',
      decimalDelimiter: template?.decimalDelimiter ?? '.',
      columnDelimiter: template?.columnDelimiter ?? ';',
      name: template?.name ?? '',
      shouldOutputHeaders: template?.shouldOutputHeaders ?? true,
      columns: isDoubleOrSingleEntry
        ? (template?.columns ?? [])
        : setRequiredAnalyticalFields(
            template?.columns ?? [],
            customFields,
            isAnalyticalSplitActivated,
          ),
      language:
        template?.language == null || template?.language === 'en-GB'
          ? 'en'
          : template?.language,
      accountingSoftware: initialAccountingSoftware,
      customSoftwareName: template?.customSoftwareName,
      rowDisplayOptions: template?.rowDisplayOptions,
    },
    validate: (values) => {
      // TODO: Add the "close-match" validation / update logic somewhere either here or in another formik hook

      const errors: FormikErrors<typeof values> = {};

      if (values.name.trim() === '') {
        errors.name = t('exports.errors.invalidTemplateName');
      }
      const accountingSoftware = values.accountingSoftware.trim();
      if (
        !accountingSoftware ||
        !availableAccountingSoftwares
          .map(({ label }) => label)
          .includes(accountingSoftware)
      ) {
        errors.accountingSoftware = t(
          'exports.errors.invalidAccountingSoftware',
        );
      }
      if (
        accountingSoftware === othersLabel &&
        !values.customSoftwareName?.trim()
      ) {
        errors.customSoftwareName = t(
          'exports.errors.invalidAccountingSoftware',
        );
      }

      return errors;
    },
    onSubmit: async (values) => {
      // FIXME: we don't send tracking information for template edition as we
      // used to in CreateExport.
      try {
        await saveTemplate(
          {
            ...values,
            accountingSoftware:
              values.accountingSoftware === othersLabel
                ? 'Others'
                : values.accountingSoftware,
          },
          templateId,
        );
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: isTemplateEditionMode
            ? t('exports.messages.errorEditExport')
            : t('exports.messages.errorCreateExport'),
        });
        throw error;
      }

      pushNotif({
        type: NotificationType.Success,
        message: isTemplateEditionMode
          ? t('exports.messages.successEditExport')
          : t('exports.messages.successCreateExport'),
      });
      onSave();
    },
  });
  const previousValues = usePrevious(formikProps.values);

  useEffect(() => {
    fetchTemplate(isTemplateEditionMode ? templateId : 'default', true);

    return () => {
      resetOpenedTemplate();
    };
  }, [fetchTemplate, resetOpenedTemplate, isTemplateEditionMode, templateId]);

  // Recompute sample rows on change
  useAsync(async () => {
    if (
      !hasLoadedTemplate ||
      // Only recompute if the changes actually affect the output
      !(
        formikProps.values.dateDelimiter !== previousValues?.dateDelimiter ||
        formikProps.values.dateFormat !== previousValues?.dateFormat ||
        formikProps.values.decimalDelimiter !==
          previousValues?.decimalDelimiter ||
        formikProps.values.language !== previousValues?.language ||
        formikProps.values.rowDisplayOptions !==
          previousValues?.rowDisplayOptions ||
        !isEqual(formikProps.values.columns, previousValues?.columns)
      )
    ) {
      return;
    }

    setIsComputingRows(true);

    const { data: preview } = await companyAPI.post(
      '/accounting-export-templates/preview',
      toApiTemplate(formikProps.values, companyId),
      { companyId },
    );

    setRows(preview);
    setIsComputingRows(false);
  }, [
    formikProps.values,
    previousValues,
    companyId,
    templateId,
    hasLoadedTemplate,
  ]);

  return (
    <TemplateEditorForm
      {...formikProps}
      customFields={customFields}
      expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
      rows={rows}
      compositePatternPartOptions={getCompositePatternPartOptions({
        customFields,
        availableColumns: availableTemplateColumns,
        t,
      })}
      availableAccountingSoftwares={availableAccountingSoftwares}
      isTemplateEditionMode={isTemplateEditionMode}
      isLoading={!hasLoadedTemplate}
      isComputingRows={isComputingRows}
      searchAvailableColumns={(
        columnsInUse: Column[],
        query: string,
      ): Column[] =>
        searchAvailableColumns({
          columnsInUse,
          availableTemplateColumns,
          customFields,
          query,
          t,
        })
      }
      onCancel={onCancel}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  companyId: getCompanyId(state),
  template: selectors.getOpenedTemplate(state),
  availableTemplateColumns: selectors.getAvailableTemplateColumns(state),
  hasLoadedTemplate: selectors.getOpenedTemplateHasLoaded(state),
});

const mapDispatchToProps = {
  fetchTemplate: thunks.fetchTemplate,
  saveTemplate: thunks.saveTemplate,
  resetOpenedTemplate: actions.resetOpenedTemplate,
};

export const TemplateEditorFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateEditorFormManager);

const setRequiredAnalyticalFields = (
  columns: Column[],
  customFields: CustomField[],
  isAnalyticalSplitActivated: boolean,
) =>
  columns.map((column) => {
    if (column.type === 'defined' && column.reference === 'costCenter') {
      return {
        ...column,
        isRequired: isAnalyticalSplitActivated ? true : column.isRequired,
      };
    }
    if (column.type === 'customField') {
      const isSplittableAnalyticalField =
        customFields.find(({ id }) => id === column.customFieldId)
          ?.is_splittable === true;

      return {
        ...column,
        isRequired: isAnalyticalSplitActivated
          ? isSplittableAnalyticalField
          : column.isRequired,
        isReorderable: isAnalyticalSplitActivated
          ? !isSplittableAnalyticalField
          : column.isReorderable,
      };
    }
    return column;
  });

const getAvailableAccountingSoftwares: (
  othersLabel: string,
) => { key: string; label: string }[] = (othersLabel) => [
  ...AccountingSoftwaresValues.map((v) => ({ key: v, label: v })),
  { key: 'Others', label: othersLabel },
];
