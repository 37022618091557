import { useTranslation } from 'src/core/common/hooks/useTranslation';
import appConfig from 'src/core/config';
import { routeFor, routes } from 'src/core/constants/routes';
import { useJiraLink } from 'src/core/modules/jira-issue-reporter/useJiraLink';

import { useCompany } from '../../hooks/useCompany';

export type OptionRoute = {
  label: string;
  url: string;
  category: string;
  hiddenTags?: string[];
  isExternal: boolean;
};

export const useCreateRoutes = (): OptionRoute[] => {
  const { t } = useTranslation('global');
  const company = useCompany();

  const jiraLink = useJiraLink();
  // The first 8 routes are shown by default (Look for routesToSearch.slice(0, 8))
  return [
    {
      label: t('homepage.name'),
      url: routeFor(routes.HOMEPAGE.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: t('requests.yourRequests'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'mine',
      }),
      category: t('misc.request_plural'),
    },
    {
      label: t('requests.inboxInvoicesSection'),
      url: routeFor(routes.INBOX_INVOICES.path, {
        company: company.id,
      }),
      category: t('inboxInvoices.filters.yourInbox'),
    },
    {
      label: t('requests.panel.newPurchaseTitle'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'virtual',
      }),
      category: t('misc.newRequest_short'),
    },
    {
      label: t('profile.navigation.profileInfo'),
      url: routeFor(routes.ACCOUNT_ME_PROFILE.path, {
        company: company.id,
      }),
      category: t('profile.profileV2Title'),
    },
    {
      label: t('profile.navigation.bankAndSecurityInfo'),
      url: routeFor(routes.ACCOUNT_ME_BANKINFO.path, {
        company: company.id,
      }),
      category: t('profile.profileV2Title'),
    },
    {
      label: t('profile.navigation.roleAndPermissions'),
      url: routeFor(routes.ACCOUNT_ME_ROLES.path, {
        company: company.id,
      }),
      category: t('profile.profileV2Title'),
    },
    {
      label: t('profile.navigation.notifications'),
      url: routeFor(routes.ACCOUNT_NOTIFICATIONS_GENERAL.path, {
        company: company.id,
      }),
      category: t('profile.profileV2Title'),
    },
    {
      label: t('expenseClaims.reviewSection'),
      url: routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
        company: company.id,
      }),
      category: t('misc.expenseClaimsTitle'),
    },
    {
      label: t('expenseClaims.paySectionWithWireTransfer'),
      url: routeFor(routes.EXPENSE_CLAIMS_PAY.path, {
        company: company.id,
      }),
      category: t('misc.expenseClaimsTitle'),
    },
    {
      label: t('expenseClaims.confirmSection'),
      url: routeFor(routes.EXPENSE_CLAIMS_CONFIRM.path, {
        company: company.id,
      }),
      category: t('misc.expenseClaimsTitle'),
    },
    {
      label: t('expenseClaims.historySection'),
      url: routeFor(routes.EXPENSE_CLAIMS_HISTORY.path, {
        company: company.id,
      }),
      category: t('misc.expenseClaimsTitle'),
    },
    {
      label: t('invoices.reviewSection'),
      url: routeFor(routes.INVOICES_REVIEW.path, {
        company: company.id,
      }),
      category: t('misc.invoicesTitle'),
    },
    {
      label: t('invoices.paySection'),
      url: routeFor(routes.INVOICES_PAY.path, {
        company: company.id,
      }),
      category: t('misc.invoicesTitle'),
    },
    {
      label: t('invoices.confirmSection'),
      url: routeFor(routes.INVOICES_CONFIRM.path, {
        company: company.id,
      }),
      category: t('misc.invoicesTitle'),
    },
    {
      label: t('invoices.historySection'),
      url: routeFor(routes.INVOICES_HISTORY.path, {
        company: company.id,
      }),
      category: t('misc.invoicesTitle'),
    },
    {
      label: t('payments.allPaymentsTitle'),
      url: routeFor(routes.PAYMENTS_ALL.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: t('payables.allPayablesTitle'),
      url: routeFor(routes.PAYABLES_ALL.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: t('requests.allRequests'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
      }),
      category: t('misc.request_plural'),
    },
    {
      label: t('requests.toApprove'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'to-approve',
      }),
      category: t('misc.request_plural'),
    },
    {
      label: t('expenseInbox.navbar.prepare'),
      url: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: company.id,
      }),
      category: t('misc.bookkeepTitle'),
    },
    {
      label: t('expenseInbox.navbar.export'),
      url: routeFor(routes.EXPENSE_INBOX_EXPORT.path, {
        company: company.id,
      }),
      category: t('misc.bookkeepTitle'),
    },

    {
      label: t('requests.panel.newReimbursement'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'reimbursement',
      }),
      category: t('misc.newRequest_short'),
      hiddenTags: [t('requests.filters.expense')],
    },
    {
      label: t('requests.panel.submitNewInvoiceOrCreditNote'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'invoice',
      }),
      category: t('misc.newRequest_short'),
    },
    {
      label: t('requests.panel.newMileageAllowanceTitle'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'mileage',
      }),
      category: t('misc.newRequest_short'),
    },
    {
      label: t('forms.perDiemAllowance.title'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'perdiem',
      }),
      category: t('misc.newRequest_short'),
    },
    {
      label: t(
        'requests.panel.purchaseOrder.newPurchaseOrderRequestPanelTitle',
      ),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'purchase-order',
      }),
      category: t('misc.newRequest_short'),
    },
    {
      label: t('requests.panel.newProcurementTitle'),
      url: `${appConfig.procurementBaseUrl}/requests/new`,
      category: t('misc.newRequest_short'),
      isExternal: true,
    },
    {
      label: t('misc.procurementTitle'),
      url: appConfig.procurementBaseUrl,
      category: '',
      isExternal: true,
    },
    {
      label: t('wallet.navigation.details'),
      url: routeFor(routes.COMPANY_BANK_DETAIL.path, {
        company: company.id,
      }),
      category: t('settings.links.yourWallet'),
    },
    {
      label: t('wallet.navigation.funding'),
      url: routeFor(routes.COMPANY_BANK_FUNDING.path, {
        company: company.id,
      }),
      category: t('settings.links.yourWallet'),
    },
    {
      label: t('wallet.navigation.accountStatements'),
      url: routeFor(routes.COMPANY_BANK_STATEMENTS.path, {
        company: company.id,
      }),
      category: t('settings.links.yourWallet'),
    },

    {
      label: t('settings.links.members'),
      url: routeFor(routes.COMPANY_MEMBERS.path, {
        company: company.id,
      }),
      category: t('navigation.organisation'),
    },
    {
      label: t('settings.links.costCenters'),
      url: routeFor(routes.COST_CENTERS.path, {
        company: company.id,
      }),
      category: t('navigation.organisation'),
    },
    {
      label: t('members.navLinks.teams'),
      url: routeFor(routes.COMPANY_TEAMS.path, {
        company: company.id,
      }),
      category: t('navigation.organisation'),
    },
    {
      label: t('budget.mainMenuItem'),
      url: routeFor(routes.BUDGETARY_EXERCISES.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: t('requests.draftsSection'),
      url: routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'drafts',
      }),
      category: t('misc.request_plural'),
    },
    {
      label: t('generalSettings.navLinks.companyInformation'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path, {
        company: company.id,
      }),
      category: t('navigation.mySpendeskPlan'),
    },
    {
      label: t('billing.navLinks.companyPlan'),
      url: routeFor(routes.COMPANY_BILLING_PLAN.path, {
        company: company.id,
      }),
      category: t('navigation.mySpendeskPlan'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionBankAccounts'),
      url: routeFor(routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionExpenseAccounts'),
      url: routeFor(routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionTaxAccounts'),
      url: routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionSupplierAccounts'),
      url: routeFor(routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionEmployeeAccounts'),
      url: routeFor(routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionAccountingSoftware'),
      url: routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('settings.links.exports'),
      url: routeFor(routes.COMPANY_EXPORTS.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('bookkeep.integrations.settings.sectionBookkeepingSetup'),
      url: routeFor(routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('settings.links.accountsPayable'),
      url: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
    },
    {
      label: t('expenseCategories.analyticalFields'),
      url: routeFor(routes.COMPANY_CATEGORIES.path, {
        company: company.id,
      }),
      category: t('navigation.accounting'),
      hiddenTags: [
        'custom fields',
        t('budget.overviewPage.expenseCategory'),
        t('expenseCategories.expenseCategory'),
      ],
    },
    {
      label: t('integration.sideNav.all'),
      url: routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
        company: company.id,
      }),
      category: t('navigation.integrations'),
    },
    {
      label: 'Purchase order page',
      url: routeFor(routes.PURCHASE_ORDERS.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: 'Legals page',
      url: routeFor(routes.LEGALS.path, {
        company: company.id,
      }),
      category: '',
    },
    {
      label: t('cards.physicalCardsTitle'),
      url: routeFor(routes.CARDS.path, {
        company: company.id,
      }),
      category: t('misc.card_plural'),
    },
    {
      label: t('cards.myCardTitle'),
      url: routeFor(routes.CARD.path, {
        company: company.id,
      }),
      category: t('misc.card_plural'),
    },
    {
      label: t('misc.subscription_plural'),
      url: routeFor(routes.SUBSCRIPTIONS.path, {
        company: company.id,
      }),
      category: t('misc.card_plural'),
    },
    {
      label: t('subscriptions.requestNewCard'),
      url: `${routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: 'all',
        id: 'new',
        newRequestType: 'virtual',
      })}?recurring`,
      category: t('misc.newRequest_short'),
    },
    {
      label: t('members.navLinks.policies'),
      url: routeFor(routes.COMPANY_POLICIES.path, {
        company: company.id,
      }),
      category: t('navigation.companyRules'),
    },
    {
      label: t('generalSettings.navLinks.notifications'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path, {
        company: company.id,
      }),
      category: t('navigation.companyRules'),
    },
    {
      label: t('members.navLinks.controlRules'),
      url: routeFor(routes.COMPANY_CONTROL_RULES.path, {
        company: company.id,
      }),
      category: t('navigation.companyRules'),
    },
    {
      label: t('generalSettings.navLinks.paymentMethods'),
      url: routeFor(routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path, {
        company: company.id,
      }),
      category: t('navigation.companyRules'),
    },
    {
      label: t('dropdownLinks.logout'),
      url: '/auth/login',
      isExternal: true,
      category: '',
    },
    {
      label: t('dropdownLinks.help'),
      url: 'https://helpcenter.spendesk.com/',
      category: '',
      isExternal: true,
    },
    jiraLink
      ? {
          label: 'Report an issue',
          url: jiraLink,
          category: 'Spendesk app support',
          hiddenTags: ['SSD'],
          isExternal: true,
        }
      : undefined,
  ].filter(Boolean) as OptionRoute[];
};
