import { useState } from 'react';

// TODO Should be moved to common components folder
// FIXME Make the component more generic
export const OrganisationReportingEntityListSearch = <T extends object>({
  entities,
  searchableFields,
  sortFn,
  children,
}: {
  entities: T[];
  searchableFields: (keyof T)[];
  sortFn: (a: T, b: T) => number;
  children: ({
    search,
    setSearch,
    filteredEntities,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredEntities: T[];
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState('');
  const simplifiedSearch = search.toLowerCase();

  return children({
    search,
    setSearch,
    filteredEntities: entities
      .filter((entity) =>
        searchableFields
          .map((field) => String(entity[field]).toLowerCase())
          .some((entityField) => entityField.includes(simplifiedSearch)),
      )
      .sort(sortFn),
  });
};
