import { gql } from 'graphql-tag';

import { useQuery } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { allPaymentsQuery } from '../../payments/graphql/allPayments/query';

type PaymentLateReceiptsResponse = {
  company: {
    payments: {
      edges: unknown[];
    };
  };
};

type LateReceiptsStats = {
  lateReceipts: number;
};

export const useOrganisationEntityLateReceiptsQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<LateReceiptsStats> => {
  const variables = {
    companyId,
    filtersV1: ['LateReceipt'],
  };

  return useQuery<LateReceiptsStats, PaymentLateReceiptsResponse>({
    key: ['organisation-reporting', companyId, 'payment-late-receipts'],
    request: {
      type: 'graphQL',
      target: 'v1',
      query: gql`
        ${allPaymentsQuery}
      `,
      variables,
    },
    isEnabled: options.isEnabled,
    reshapeData: ({ company }) => ({
      lateReceipts: company.payments.edges.length,
    }),
  });
};
