import { Callout } from '@dev-spendesk/grapes';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';

type Props = {
  numberOfMembersWithMissingManagers: number;
};

export const ManagersWarningCallout = ({
  numberOfMembersWithMissingManagers,
}: Props) => {
  const { t } = useTranslation('global');
  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();

  const isCompanyManagedByIntegrationResult = unwrapQuery(
    isCompanyManagedByIntegrationQueryState,
  );
  const isCompanyManagedByIntegration =
    isCompanyManagedByIntegrationResult?.isManagedByKombo ?? false;
  const featureSet = isCompanyManagedByIntegrationResult?.featureSet ?? [];
  const includesReportingManagers = featureSet.includes(
    'includesReportingManagers',
  );

  const getDescription = () => {
    if (!isCompanyManagedByIntegration) {
      return t(
        'members.reportingManagers.missingManagersCallout.description.default',
      );
    }

    return includesReportingManagers
      ? t(
          'members.reportingManagers.missingManagersCallout.description.managerSyncEnabled',
        )
      : t(
          'members.reportingManagers.missingManagersCallout.description.managerSyncDisabled',
        );
  };

  return (
    <Callout
      data-testid="MissingManagersCallout"
      className="mb-s"
      iconName="warning"
      variant="warning"
      title={t('members.reportingManagers.missingManagersCallout.title', {
        count: numberOfMembersWithMissingManagers,
      })}
    >
      {getDescription()}
    </Callout>
  );
};
