import React from 'react';

import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export function getVatAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.taxAccountsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.taxAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.taxAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.taxAccountsDescription';
    case 'Sage100':
      return 'bookkeep.integrations.sage100.taxAccountsDescription';
    case 'Quickbooks':
      return 'quickbooks';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getReverseChargeAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Xero':
      return 'xero';
    case 'Datev':
      return 'bookkeep.integrations.datev.reverseChargeAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.reverseChargeAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.reverseChargeAccountsDescription';
    case 'Quickbooks':
      return 'quickbooks';
    case 'Sage100':
      return 'bookkeep.integrations.sage100.reverseChargeAccountsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getVatAccountsHelpCenterLink(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5556277-set-up-tax-accounts-on-datev-bookkeep-2-0';
    default:
      return 'https://helpcenter.spendesk.com/articles/4136555-set-up-your-accounting-codes';
  }
}

export function getReverseChargeAccountsHelpCenterLink(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5556277-set-up-tax-accounts-on-datev-bookkeep-2-0';
    default:
      return 'https://helpcenter.spendesk.com/articles/4136555-set-up-your-accounting-codes';
  }
}

export function getVatAddEditDescription(
  t: TGlobalFunctionTyped,
  integration: AccountingSoftware,
) {
  switch (integration) {
    case 'Xero':
      return <>'xero'</>;
    case 'Datev':
      return (
        <>
          {t('bookkeep.integrations.datev.vatAddEdit.subtitle')}
          <a
            href="https://apps.datev.de/help-center/documents/1008613"
            target="_blank"
            rel="noreferrer"
          >
            {t('bookkeep.integrations.datev.vatAddEdit.list')}
          </a>
        </>
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return <></>;
    case 'Netsuite':
      return <>netsuite</>;
    case 'Quickbooks':
      return <>TO DO</>;
    case 'Sage100':
      return <>TO DO</>;
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getReverseChargeAddEditDescription(
  t: TGlobalFunctionTyped,
  integration: AccountingSoftware,
) {
  switch (integration) {
    case 'Xero':
      return <>'xero'</>;
    case 'Datev':
      return (
        <>
          {t('bookkeep.integrations.datev.reverseChargeAddEdit.subtitle')}
          <a
            href="https://apps.datev.de/help-center/documents/1008613"
            target="_blank"
            rel="noreferrer"
          >
            {t('bookkeep.integrations.datev.reverseChargeAddEdit.list')}
          </a>
        </>
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return <></>;
    case 'Netsuite':
      return <>netsuite</>;
    case 'Quickbooks':
      return <>TO DO</>;
    case 'Sage100':
      return <>Sage100</>;
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getVatCodeTranslationKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Datev':
      return 'bookkeep.integrations.datev.vatTableBuCode';
    default:
      return 'bookkeep.integrations.settings.vatTable.code';
  }
}
