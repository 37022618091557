import { Callout } from '@dev-spendesk/grapes';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import type { CardAddress } from 'src/core/common/hooks/useCardAddressQuery';

import { MobileBanner } from './MobileBanner/MobileBanner';
import { PlasticCardControlSettings } from './PlasticCardControlSettings';
import { PlasticCardHeader } from './PlasticCardHeader';
import { PlasticCardNotice } from './PlasticCardNotice';
import { RecardBanner } from './RecardBanner';
import { type ApiCard, type ApiCardSettings } from '../../../card';
import { CardFeedActivity } from '../../../components/CardFeedActivity';
import { RestrictedAccessWarning } from '../../../components/RestrictedAccessWarning';
import type { CardsAccess } from '../../../hooks/useCardAccessQuery';
import { type CardActivity } from '../../../hooks/useCardActivityQuery';
import type { CardLoad } from '../../../hooks/useCardLoadsQuery';
import type { CardPayment } from '../../../hooks/useCardPaymentsQuery';

import './PlasticCard.css';

type Props = {
  card: ApiCard;
  cardSettings: ApiCardSettings | undefined;
  cardAddress: CardAddress | undefined;
  cardActivity: CardActivity[] | undefined;
  cardPayments: CardPayment[] | undefined;
  cardLoads: CardLoad[] | undefined;
  cardsAccess: CardsAccess | undefined;
  refreshCard(): void;
};

export const PlasticCard = ({
  card,
  cardSettings,
  cardAddress,
  cardActivity,
  cardPayments,
  cardLoads,
  cardsAccess,
  refreshCard,
}: Props) => {
  const { t } = useTranslation('global');

  const user = useUser();
  const company = useCompany();

  const isContactlessEnabled =
    cardSettings?.authorisations.isContactlessEnabled;

  const isCompanyOnSfsAndCardOnBankable =
    company.banking_provider === 'sfs' && card.banking_provider === 'bankable';

  const displayRecardBanner =
    card.recard_request && !isCompanyOnSfsAndCardOnBankable;

  const displayRestrictedAccessWarning = cardsAccess?.hasAccess === false;
  const displayMobileBanner =
    !displayRecardBanner &&
    !displayRestrictedAccessWarning &&
    !user.has_device &&
    company.banking_provider === 'sfs' &&
    company.currency !== 'GBP' &&
    card.banking_provider === 'sfs';

  return (
    <div className="PlasticCard">
      <div className="PlasticCard__container">
        {displayRecardBanner && card.recard_request && (
          <ErrorBoundary context={{ scope: 'recard', team: 'capture' }}>
            <RecardBanner
              company={company}
              recardRequest={card.recard_request}
            />
          </ErrorBoundary>
        )}
        {displayRestrictedAccessWarning && (
          <div className="mb-m">
            <RestrictedAccessWarning
              initiator={cardsAccess.initiator}
              isFullPage
            />
          </div>
        )}

        {isContactlessEnabled && card.transaction_count === 0 && (
          <Callout
            variant="info"
            title={t('cards.contactlessNotice')}
            className="mb-m"
          />
        )}
        {displayMobileBanner && <MobileBanner />}
        <h1 className="title-xxl">{t('cards.myCardTitle')}</h1>
        <PlasticCardHeader
          card={card}
          cardsAccess={cardsAccess}
          syncActivity={refreshCard}
        />
        {cardAddress && <PlasticCardNotice cardAddress={cardAddress} />}
        {cardSettings && (
          <PlasticCardControlSettings cardSettings={cardSettings} />
        )}
        {cardActivity && cardPayments && cardLoads && (
          <>
            <h2 className="mb-m mt-l text-complementary title-l">
              {t('cards.recentActivity')}
            </h2>
            <CardFeedActivity
              cardActivity={cardActivity}
              cardPayments={cardPayments}
              cardLoads={cardLoads}
            />
          </>
        )}
      </div>
    </div>
  );
};
