import { CustomFieldsSelector } from 'src/core/common/components/CustomFieldsSelector/CustomFieldsSelector';

import {
  type CustomFieldAssociation,
  type CustomFieldEligibleType,
  partialUpdateCustomFieldAssociation,
} from '../../models/customFieldAssociation';
import type { CustomFieldDefinition } from '../../models/customFieldDefinition';
import type { TeamId } from '../../models/team';

type CustomFieldsFormFieldError = 'required';

export type CustomFieldsFormFieldProps = {
  className?: string;
  fit?: 'parent' | 'content' | undefined;
  placement?: 'bottom-start' | 'top-end' | 'top-start' | undefined;
  customFields: CustomFieldDefinition[];
  value: CustomFieldAssociation[];
  types: CustomFieldEligibleType[];
  error?: {
    [customFieldId: string]: CustomFieldsFormFieldError;
  };
  teamId: TeamId | undefined;
  isDisabled?: boolean;
  onSelect(
    newValue: CustomFieldAssociation[],
    updatedCustomFieldId: string,
  ): void;
};

export const CustomFieldsFormField = ({
  className,
  fit,
  placement,
  isDisabled,
  customFields,
  value,
  types,
  error,
  teamId,
  onSelect,
}: CustomFieldsFormFieldProps) => {
  const handleCustomFieldAssociationChange = (
    updatedCustomFieldAssociations: CustomFieldAssociation[],
    customField?: { id: string } | undefined,
  ): void => {
    onSelect(
      partialUpdateCustomFieldAssociation(
        value,
        updatedCustomFieldAssociations,
      ),
      customField?.id ?? '',
    );
  };

  return (
    <div className={className}>
      <CustomFieldsSelector
        fit={fit}
        placement={placement}
        isDisabled={isDisabled}
        team={teamId}
        types={types}
        customFields={customFields}
        customFieldsValues={reshapeCustomFieldsAssociationForSelector(value)}
        errors={error}
        onChange={handleCustomFieldAssociationChange}
      />
    </div>
  );
};

// Reshape the values for `CustomFieldsSelector` because it accepts a specific format as `customFieldsValues` props
const reshapeCustomFieldsAssociationForSelector = (
  customFieldAssociations: CustomFieldAssociation[],
) => {
  return customFieldAssociations.map((customFieldAssociation) => {
    return {
      field: {
        id: customFieldAssociation.customFieldId,
      },
      value: {
        id: customFieldAssociation.customFieldValueId,
        value: customFieldAssociation.value,
      },
    };
  });
};
