import { useUser } from '../../app/hooks/useUser';
import { type OrganisationReportingEntity } from '../types';

export const useOrganisationCompanyList = (): OrganisationReportingEntity[] => {
  const user = useUser();

  return Object.entries(user.data_by_company)
    .filter(([_, company]) => company.is_account_owner || company.is_controller)
    .map(([companyId, company]) => {
      return {
        id: companyId,
        name: company.name,
        country: company.country,
        currency: company.currency,
        type: company.type,
        isChurning: Boolean(company.churning_at),
        hasChurned: Boolean(company.churned_at),
      };
    });
};
