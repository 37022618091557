import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep';
import { isIntegrationStatusWithIntegration } from 'src/core/modules/bookkeep/integration/status';

import { DisclosureItem } from '../DisclosureItem/DisclosureItem';
import { SubNavLink } from '../SubNavItem/SubNavItem';

type Props = {
  company: {
    id: string;
    churning_at: string | null;
  };
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_organisation_owner: boolean;
  };
};

export const SettingsLink = ({ company, user }: Props) => {
  const { t } = useTranslation('global');
  const isAccountingSettingsEnabled = useFeature(FEATURES.SETTINGS_ACCOUNTING);
  const isAccountingExportsSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTING_EXPORTS,
  );
  const isCustomFieldsSettingsEnabled = useFeature(FEATURES.CUSTOM_FIELDS);
  const isAccountsPayableSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTS_PAYABLE,
  );

  const isAOorController = user.is_account_owner || user.is_controller;

  const canAccessSpendeskPlan =
    user.is_organisation_owner || isAOorController || user.is_admin;

  const canAccessChartOfAccounts =
    (isAccountingSettingsEnabled || isAccountsPayableSettingsEnabled) &&
    !company.churning_at &&
    isAOorController;

  const canAccessAccountingCustomFields =
    isCustomFieldsSettingsEnabled && (isAOorController || user.is_admin);

  const canAccessAccounting =
    (isAccountingSettingsEnabled || isAccountingExportsSettingsEnabled) &&
    !company.churning_at &&
    isAOorController;

  const canAccessIntegrations = !company.churning_at && isAOorController;
  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery(
    false,
    {
      isEnabled: canAccessChartOfAccounts,
    },
  );

  let hasActiveIntegration = false;
  if (accountingIntegrationStatusQueryResult.status === 'success') {
    hasActiveIntegration = isIntegrationStatusWithIntegration(
      accountingIntegrationStatusQueryResult.data,
    );
  }

  return (
    <DisclosureItem summary={t('misc.setting_plural')} iconName="settings">
      {canAccessSpendeskPlan && (
        <li>
          <SubNavLink
            href={routeFor(
              routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
              {
                company: company.id,
              },
            )}
            activePaths={[
              routes.COMPANY_GENERAL_SETTINGS_COMPANY_INFORMATION.path,
              routes.COMPANY_BILLING_PLAN.path,
              routes.COMPANY_BILLING_INFORMATION.path,
            ]}
          >
            {t('navigation.mySpendeskPlan')}
          </SubNavLink>
        </li>
      )}
      {canAccessChartOfAccounts && hasActiveIntegration && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
              routes.COMPANY_ACCOUNTS_PAYABLE.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
              routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
            ]}
            href={routeFor(routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path, {
              company: company.id,
            })}
          >
            {t('navigation.chartOfAccounts')}
          </SubNavLink>
        </li>
      )}

      {(canAccessAccounting || canAccessAccountingCustomFields) && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_ACCOUNTING_INTEGRATION.path,
              routes.COMPANY_EXPORTS.path,
              routes.COMPANY_CATEGORIES.path,
              routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
            ]}
            href={routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
              company: company.id,
            })}
          >
            {t('navigation.accounting')}
          </SubNavLink>
        </li>
      )}
      {canAccessIntegrations && (
        <li>
          <SubNavLink
            href={routeFor(routes.COMPANY_INTEGRATIONS.path, {
              company: company.id,
            })}
          >
            {t('navigation.integrations')}
          </SubNavLink>
        </li>
      )}
    </DisclosureItem>
  );
};
