import React from 'react';

export const EntityGenericStatisticsBlock = ({
  title,
  contents,
  isInactive = false,
  stack = false,
}: {
  title: string;
  contents: React.ReactNode[];
  stack?: boolean;
  isInactive?: boolean;
}) => (
  <div
    className={`box-border flex h-full w-full flex-col ${stack ? 'flex-start' : 'justify-between'} gap-xxs`}
  >
    <div className="uppercase text-neutral-dark body-s">{title}</div>
    {React.Children.map(contents, (content) => (
      <div
        key={content?.toString()}
        className={isInactive ? 'text-neutral' : ''}
      >
        {content}
      </div>
    ))}
  </div>
);
