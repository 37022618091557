import { createAction, type Dispatch } from '@reduxjs/toolkit';
import queryString from 'query-string';

import * as types from 'src/core/actionTypes/users';
import { companyAPI } from 'src/core/api/axios';
import {
  addNotification,
  NotificationType,
} from 'src/core/modules/app/notifications';
import { enrichUser, spxEnrichUser } from 'src/core/utils/entities/user';

import { type AppState } from '../reducers';
import { getBankInfoIsLoading } from '../selectors/globalSelectors';
import { getCompanyId } from '../selectors/globalSelectorsTyped';

const fetchUsersLoading = createAction(types.FETCH_USERS_LOADING);
const fetchUsersFailure = createAction(types.FETCH_USERS_FAILURE);
export const fetchUsersSuccess = createAction(types.FETCH_USERS_SUCCESS);
export const fetchUsers =
  (withPending = false) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(fetchUsersLoading());

    let users;
    const { company } = getState().global;

    try {
      const qs = queryString.stringify({
        includePending: Boolean(withPending),
      });
      const endpoint = `/users?${qs}`;
      const res = await companyAPI.get(endpoint, {
        companyId: company?.id ?? '',
      });

      users = res.data;
      if (!Array.isArray(users)) {
        return dispatch(fetchUsersFailure(users));
      }
    } catch (error) {
      dispatch(fetchUsersFailure(error.response && error.response.data));
    }

    // @ts-expect-error company is possibly null
    const enrichedUsers = users.map((user) => enrichUser(user, company));
    dispatch(fetchUsersSuccess(enrichedUsers));
  };

const fetchUserLoading = createAction(types.FETCH_USER_LOADING);
const fetchUserFailure = createAction(types.FETCH_USER_FAILURE);
const fetchUserSuccess = createAction(types.FETCH_USER_SUCCESS);
export const fetchUser =
  (userId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(fetchUserLoading());

    const { company } = getState().global;
    let user;

    try {
      const res = await companyAPI.get(`/users/${userId}`, {
        companyId: company?.id ?? '',
      });
      user = res.data;
      user.bankInfo = user.data_by_company[company?.id ?? ''].bank_info;
    } catch (error) {
      dispatch(fetchUserFailure(error));
      throw error;
    }

    // @ts-expect-error company is possibly null
    const enrichedUser = enrichUser(user, company);
    // @ts-expect-error fetchUserSuccess is badly typed
    dispatch(fetchUserSuccess(enrichedUser));
    // FIXME: Don't return the user; needed for fetchRequest
    return enrichedUser;
  };

export const updateMeLocally =
  (payload: unknown) => (dispatch: Dispatch, getState: () => AppState) => {
    const { company, user } = getState().global;
    // @ts-expect-error company is possibly null
    const rawUser = spxEnrichUser({ ...user, ...payload }, company);
    dispatch({
      type: types.UPDATE_ME_LOCALLY,
      payload: {
        // @ts-expect-error company is possibly null
        user: enrichUser(rawUser, company),
      },
    });
  };

export const refreshUserCompanies =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const userId = state.global.user?.id ?? '';
    const companyId = state.global.company?.id ?? '';

    const { data } = await companyAPI.get(`/users/${userId}`, {
      companyId,
      params: { includeFirstAccesses: true },
    });

    dispatch(
      // @ts-expect-error actions are badly typed
      updateMeLocally({
        data_by_company: data.data_by_company,
      }),
    );
  };

const saveMeBankInfoLoading = createAction(types.SAVE_ME_BANK_INFO_LOADING);
const saveMeBankInfoSuccess = createAction(types.SAVE_ME_BANK_INFO_SUCCESS);
const saveMeBankInfoFailure = createAction(types.SAVE_ME_BANK_INFO_FAILURE);
export const saveMeBankInfo =
  (payload: unknown) =>
  async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const userId = state.global.user?.id ?? '';
    const companyId = state.global.company?.id ?? '';

    if (getBankInfoIsLoading(state)) {
      return;
    }

    dispatch(saveMeBankInfoLoading());

    try {
      await companyAPI.post(`/users/${userId}/bank-info`, payload, {
        companyId,
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        error.code = 'invalid_bank_info';
      } else {
        error.code = 'unknown';
      }

      dispatch(saveMeBankInfoFailure(error));
      throw error;
    }

    // @ts-expect-error actions are badly typed
    dispatch(updateMeLocally({ bankInfo: payload }));
    dispatch(saveMeBankInfoSuccess());
  };

export const saveMeBankInfoAndNotify =
  (payload: unknown, messages: { error: string; success: string }) =>
  async (dispatch: Dispatch) => {
    try {
      // @ts-expect-error actions are badly typed
      await dispatch(saveMeBankInfo(payload));
    } catch (error) {
      if (error.code === 'unknown') {
        dispatch(
          // @ts-expect-error addNotification is badly typed
          addNotification({
            type: NotificationType.Danger,
            message: messages.error,
          }),
        );
      }

      throw error;
    }

    dispatch(
      // @ts-expect-error addNotification is badly typed
      addNotification({
        type: NotificationType.Success,
        message: messages.success,
      }),
    );
  };

const fetchMeBankInfoLoading = createAction(types.FETCH_ME_BANK_INFO_LOADING);
const fetchMeBankInfoSuccess = createAction(types.FETCH_ME_BANK_INFO_SUCCESS);
const fetchMeBankInfoFailure = createAction(types.FETCH_ME_BANK_INFO_FAILURE);
export const fetchMeBankInfo =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const userId = state.global.user?.id ?? '';
    const companyId = state.global.company?.id ?? '';

    if (getBankInfoIsLoading(getState())) {
      return;
    }

    dispatch(fetchMeBankInfoLoading());

    let bankInfo;
    try {
      const res = await companyAPI.get(`/users/${userId}/bank-info`, {
        companyId,
      });
      bankInfo = res.data;
    } catch (error) {
      if (error?.response?.status !== 404) {
        dispatch(fetchMeBankInfoFailure());
        throw error;
      }
    }

    // @ts-expect-error actions are badly typed
    dispatch(updateMeLocally({ bankInfo }));
    dispatch(fetchMeBankInfoSuccess());
  };

export const fetchMeCostCenter =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const companyId = getCompanyId(state);

    const { data } = await companyAPI.get(`/cost-centers/me`, {
      companyId,
    });

    const partialUpdate = { costCenter: data };

    // @ts-expect-error actions are badly typed
    dispatch(updateMeLocally(partialUpdate));
  };
