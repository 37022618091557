import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useBudgetaryExercisesQuery } from 'modules/budgets/containers/hooks/useBudgetaryExercisesQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';
import { useUser, type User } from 'src/core/modules/app/hooks/useUser';
import { useAccessibleCardsLinks } from 'src/core/pages/CardsPage/CardsPage';

import { AllExpensesLink } from './NavItems/AllExpensesLink';
import { BookkeepLink } from './NavItems/BookkeepLink';
import { BudgetsLink } from './NavItems/BudgetsLink';
import { CardLink } from './NavItems/CardLink';
import { ExpendituresLink } from './NavItems/Expenditures';
import { HomeLink } from './NavItems/HomeLink';
import { InboxLink } from './NavItems/InboxLink';
import { MyCompanyLink } from './NavItems/MyCompany';
import { ProcurementLink } from './NavItems/ProcurementLink';
import { PurchaseOrderLink } from './NavItems/PurchaseOrderLink';
import { RequestLink } from './NavItems/RequestLink';
import { SettingsLink } from './NavItems/SettingsLink';
import { useCompany } from '../../../hooks/useCompany';

type MainNavigationLink = (props: {
  user: {
    is_account_owner: boolean;
    is_organisation_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
  company: {
    id: string;
    churning_at: string | null;
  };
}) => JSX.Element | null;

type MainNavigationItem = { name: string; component: MainNavigationLink };

function useHasVisibleBudgets(enableQuery: boolean): boolean {
  const allBudgetaryExercicesQueryState =
    useBudgetaryExercisesQuery(enableQuery);

  // Check if at least one budget is returned to display the link accordingly
  return useMemo(() => {
    if (
      allBudgetaryExercicesQueryState.status !== 'success' ||
      allBudgetaryExercicesQueryState.data.length === 0
    ) {
      return false;
    }
    return allBudgetaryExercicesQueryState.data.some(
      ({ budgets }) => budgets.length > 0,
    );
  }, [allBudgetaryExercicesQueryState]);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function usePrimaryNavigationLinks(user: User): MainNavigationItem[] {
  const isProcurementEnabled = useFeature(FEATURES.PROCUREMENT);
  const isBookkeepEnabled = useFeature(FEATURES.BOOKKEEP);
  const isBudgetsFeatureEnabled = useFeature(FEATURES.BUDGETS);
  const isPurchaseOrderActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );
  const accessibleCardsLinks = useAccessibleCardsLinks();

  const isAoOrController = user.is_account_owner || user.is_controller;
  const hasVisibleBudgets = useHasVisibleBudgets(
    isBudgetsFeatureEnabled && !isAoOrController,
  );

  const primaryNavigationLinks: MainNavigationItem[] = [
    {
      name: 'homepage',
      component: HomeLink,
    },
  ];

  if (isAoOrController) {
    primaryNavigationLinks.push({
      name: 'expenditures',
      component: ExpendituresLink,
    });

    if (isBookkeepEnabled) {
      primaryNavigationLinks.push({
        name: 'bookkeep',
        component: BookkeepLink,
      });
    }

    if (isProcurementEnabled) {
      primaryNavigationLinks.push({
        name: 'procurement',
        component: ProcurementLink,
      });
    }

    // My company OR Cards
    if (user.is_account_owner || user.is_admin) {
      primaryNavigationLinks.push({
        name: 'mycompany',
        component: MyCompanyLink,
      });
    } else if (accessibleCardsLinks.length > 0) {
      primaryNavigationLinks.push({ name: 'card', component: CardLink });
    }

    if (isBudgetsFeatureEnabled) {
      primaryNavigationLinks.push({
        name: 'budgets',
        component: BudgetsLink,
      });
    }

    primaryNavigationLinks.push({
      name: 'settings',
      component: SettingsLink,
    });
  } else {
    primaryNavigationLinks.push({
      name: 'inbox',
      component: InboxLink,
    });
    primaryNavigationLinks.push({
      name: 'request',
      component: RequestLink,
    });
    primaryNavigationLinks.push({
      name: 'allexpenses',
      component: AllExpensesLink,
    });

    if (accessibleCardsLinks.length > 0) {
      primaryNavigationLinks.push({ name: 'card', component: CardLink });
    }

    if (isProcurementEnabled) {
      primaryNavigationLinks.push({
        name: 'procurement',
        component: ProcurementLink,
      });
    }

    if (isPurchaseOrderActivated) {
      primaryNavigationLinks.push({
        name: 'purchase-order',
        component: PurchaseOrderLink,
      });
    }

    // Viewers budgets
    if (hasVisibleBudgets) {
      primaryNavigationLinks.push({
        name: 'budgets',
        component: BudgetsLink,
      });
    }

    if (user.is_organisation_owner) {
      primaryNavigationLinks.push({
        name: 'settings',
        component: SettingsLink,
      });
    }
  }

  return primaryNavigationLinks;
}

function shouldDisplayNewRequestButton(
  company: {
    id: string;
    churning_at: string | null;
  },
  user: {
    is_account_owner: boolean;
    is_requester: boolean;
  },
) {
  return !(
    company.churning_at ||
    (!user.is_requester && !user.is_account_owner)
  );
}

export function useNavigationItems(): {
  canCreateRequest: boolean;
  links: MainNavigationItem[];
} {
  const company = useCompany();
  const user = useUser();
  const links = usePrimaryNavigationLinks(user);

  const isVerticalNavEnabled = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const isMultiEntityHubPage = useIsMultiEntityHubPage();

  if (
    isMultiEntityHubPage &&
    // TODO: Remove condition when vertical nav is GA
    isVerticalNavEnabled
  ) {
    // The user can only see the Dashboard link, cannot create requests
    return {
      canCreateRequest: false,
      links: [{ name: 'homepage', component: HomeLink }],
    };
  }

  return {
    canCreateRequest: shouldDisplayNewRequestButton(company, user),
    links,
  };
}

export function useIsMultiEntityHubPage(): boolean {
  return !!matchPath(useLocation().pathname, routes.ORGANISATION_REPORTING);
}
