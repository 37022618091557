import { Callout, Link } from '@dev-spendesk/grapes';
import { useTranslation } from 'react-i18next';

type Props = {
  numberOfMembersWithMissingManagers: number;
};

export const ManagersPrimaryCallout = ({
  numberOfMembersWithMissingManagers,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Callout
      data-testid="MissingManagersCallout"
      className="mb-s"
      iconName="sparkles"
      variant="primary"
      title={t(
        'members.reportingManagers.missingManagersCallout.titleMissingApprovalScheme',
        {
          count: numberOfMembersWithMissingManagers,
        },
      )}
    >
      {t(
        'members.reportingManagers.missingManagersCallout.description.missingApprovalScheme',
      )}
      <Link
        className="mt-s flex text-m font-medium text-complementary no-underline"
        href="https://helpcenter.spendesk.com/articles/9910293-approval-workflows"
        target="_blank"
        isExternal
      >
        {t('misc.learnMore')}
      </Link>
    </Callout>
  );
};
