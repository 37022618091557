import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
  BackgroundVariant,
  type Edge,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useState } from 'react';

import { edgeTypes } from '../../edge-types';
import { useAddNode, useLayoutedElements } from '../../hooks';
import { type CustomNode } from '../../node';
import { nodeTypes } from '../../node-types';
import { NodeSettingsPanel } from '../NodeSettingsPanel';
import { type EdgeData } from '../node-components/LabelEdge';

export const ApprovalByDimensionsPage = () => {
  const initialEdges: Edge<EdgeData>[] = [
    {
      id: 'initialEdge',
      source: 'initialLabelNode',
      target: 'initialStandardApprovalFlowNode',
      sourceHandle: 'bottomHandle',
      type: 'labelEdge',
      data: {
        labelText: 'If no other matching condition found, then',
        labelVariant: 'info',
        direction: 'vertical',
      },
    },
  ];

  const initialNodes: CustomNode[] = [
    {
      id: 'initialLabelNode',
      position: { x: 0, y: 0 },
      draggable: false,
      type: 'labelNode',
      data: {
        text: 'When the request is made',
        nodeType: 'labelNode',
        onAddNodeClick: () => {
          addNode('initialLabelNode');
        },
      },
    },
    {
      id: 'initialStandardApprovalFlowNode',
      position: { x: -40, y: 150 },
      draggable: false,
      type: 'standardApprovalFlowNode',
      data: {
        nodeType: 'standardApprovalFlowNode',
        onEditClick: () => {
          setIsNodeDetailsPanelOpened(true);
        },
      },
    },
  ];

  const [isNodeDetailsPanelOpened, setIsNodeDetailsPanelOpened] =
    useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>(
    undefined,
  );
  const [nodes, setNodes, onNodesChange] =
    useNodesState<CustomNode>(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>(initialEdges);
  const addNode = useAddNode({
    setNodes,
    setEdges,
    onEditClick: (id: string) => {
      setIsNodeDetailsPanelOpened(true);
      setSelectedNodeId(id);
    },
    onToggleExpand: (id: string) => {
      setNodes((previousNodes) => {
        return previousNodes.map((node) => {
          if (node.type === 'baseNode' && node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                isExpanded: !node.data.isExpanded,
              },
            };
          }
          return node;
        });
      });
    },
  });
  const { layoutedNodes, layoutedEdges } = useLayoutedElements(nodes, edges);

  return (
    <div className="relative flex h-full flex-shrink flex-grow flex-col overflow-y-auto">
      <NodeSettingsPanel
        isOpen={isNodeDetailsPanelOpened}
        onClose={() => setIsNodeDetailsPanelOpened(false)}
        selectedNodeId={selectedNodeId}
      />
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={layoutedNodes}
        edges={layoutedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        fitViewOptions={{ padding: 1.5 }}
      >
        <Controls />
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </div>
  );
};
