import { ModalSlideshow } from '@dev-spendesk/grapes';
import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useCompleteOnboardingUserTaskMutation } from 'src/core/modules/onboarding/hooks/useCompleteOnboardingUserTaskMutation';
import { useGetOnboardingUserTasksQuery } from 'src/core/modules/onboarding/hooks/useGetOnboardingUserTasksQuery';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import downloadApp1Source from './assets/downloadApp_1.svg';
import downloadApp2Source from './assets/downloadApp_2.webp';
import uploadReceipt2Source from './assets/upload_receipt_2.webp';
import { UploadReceiptSlideThree } from './slides/UploadReceiptSlideThree';
import { UploadReceiptSlideTwo } from './slides/UploadReceiptSlideTwo';
import {
  getLearnToApproveAssets,
  getUploadReceiptFirstAsset,
  getUploadReceiptThirdAsset,
} from './utils';
import { type UserTaskType } from '../../../../types/userTask';

type Props = {
  setModalType: (type: UserTaskType | null) => void;
  type: UserTaskType | null;
};

export const ReadyToSpendModalSlideshow = ({ setModalType, type }: Props) => {
  const history = useHistory();

  const { activeLanguage, t } = useTranslation('global');
  const companyId = useCompanyId();

  const userTasksQueryState = useGetOnboardingUserTasksQuery();
  const userTasks = unwrapQuery(userTasksQueryState);
  const userTask = userTasks?.find((task) => task.type === type);

  const [completeOnboardingUserTaskMutation] =
    useCompleteOnboardingUserTaskMutation();

  const slides = useMemo(() => {
    switch (type) {
      case 'download_app':
        return [
          {
            title: t('readyToSpend.slideshows.downloadApp.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.downloadApp.one.content" />
            ),
            illustration: <img src={downloadApp1Source} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.downloadApp.two.title'),
            content: (
              <div>{t('readyToSpend.slideshows.downloadApp.two.content')}</div>
            ),
            illustration: <img src={downloadApp2Source} alt="" />,
          },
        ];
      case 'learn_to_approve': {
        const assets = getLearnToApproveAssets(activeLanguage);
        return [
          {
            title: t('readyToSpend.slideshows.learnToApprove.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.learnToApprove.one.content" />
            ),
            illustration: <img src={assets[0]} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.learnToApprove.two.title'),
            content: (
              <div>
                {t('readyToSpend.slideshows.learnToApprove.two.content')}
              </div>
            ),
            illustration: <img src={assets[1]} alt="" />,
          },
        ];
      }
      case 'upload_receipt':
        return [
          {
            title: t('readyToSpend.slideshows.uploadReceipt.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.uploadReceipt.one.content" />
            ),
            illustration: (
              <img src={getUploadReceiptFirstAsset(activeLanguage)} alt="" />
            ),
          },
          {
            title: t('readyToSpend.slideshows.uploadReceipt.two.title'),
            content: <UploadReceiptSlideTwo />,
            illustration: <img src={uploadReceipt2Source} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.uploadReceipt.three.title'),
            content: <UploadReceiptSlideThree />,
            illustration: (
              <video loop muted autoPlay>
                <source
                  src={getUploadReceiptThirdAsset(activeLanguage)}
                  type="video/mp4"
                />
              </video>
            ),
          },
        ];
      default:
        return [];
    }
  }, [type]);

  useEffect(() => {
    if (type) {
      track(AnalyticEventName.ONBOARDING_WALKTHROUGH_OPENED, {
        taskType: type,
        totalSteps: slides.length,
        version: 0,
      });
    }
  }, [type]);

  const closeModal = async () => {
    if (type && userTask && userTask.status !== 'completed') {
      await completeOnboardingUserTaskMutation({
        endpointParams: { taskType: type },
      });
    }
    setModalType(null);
  };

  const onClose = async ({ index }: { index: number }) => {
    await closeModal();
    track(AnalyticEventName.ONBOARDING_WALKTHROUGH_CLOSED, {
      lastStepSeen: index,
      taskType: type || '',
      totalSteps: slides.length,
      version: 0,
    });
  };

  const onDone = async () => {
    await closeModal();
    track(AnalyticEventName.ONBOARDING_WALKTHROUGH_DONE, {
      taskType: type || '',
      totalSteps: slides.length,
      version: 0,
    });

    switch (type) {
      case 'learn_to_approve': {
        const allRequestsPath = routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: 'all',
        });
        history.push(allRequestsPath);
        break;
      }
      default:
        break;
    }
  };

  if (slides.length === 0) {
    return null;
  }

  return (
    <ModalSlideshow
      isOpen={!!type}
      slides={slides}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.next'),
        done: t('misc.gotIt'),
      }}
      onDone={onDone}
      onClose={onClose}
      onCancel={() => onClose({ index: 0 })}
    />
  );
};
