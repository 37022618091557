import { colors, Icon } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useNavigationContext } from '../NavigationContext';
import { useIsMultiEntityHubPage } from '../helpers';

import './Logo.css';

export const Logo = ({ companyName }: { companyName: string }) => {
  const { t } = useTranslation('global');
  const isNavbarExpanded = useNavigationContext();
  const isMultiEntityHubPage = useIsMultiEntityHubPage();

  return (
    <div className="flex items-center gap-s">
      <Icon
        name={isMultiEntityHubPage ? 'dashboard' : 'spendesk'}
        color={colors.white}
        aria-hidden="true"
        className="ml-xxs shrink-0"
      />
      <h3
        className={classNames(
          'leading-[20px] text-white title-m',
          !isNavbarExpanded && 'hidden',
        )}
      >
        {isMultiEntityHubPage
          ? t('organisation.reporting.navLink')
          : companyName}
      </h3>
    </div>
  );
};
