import { colors, IconButton, Tag } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';

export type StandardApprovalFlowNodeData = {
  nodeType: 'standardApprovalFlowNode';
  onEditClick: () => void;
};

type Props = NodeProps & {
  data: StandardApprovalFlowNodeData;
};

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const StandardApprovalFlowNode = ({ data }: Props) => {
  return (
    <div className="flex w-[300px] items-center">
      <div className="rounded bg-complementary p-s">
        <div className="flex items-center justify-between">
          <Tag variant="primary">Standard approval workflow</Tag>
          <div className="flex items-center">
            <IconButton
              aria-label=""
              iconColor={colors.white}
              onClick={data.onEditClick}
              iconName="pen"
            />
            <IconButton
              aria-label=""
              iconColor={colors.white}
              iconName="minus"
            />
          </div>
        </div>
        <div className="mt-xs text-neutral-light body-s">
          This workflow will be executed if no other workflow applies. This
          ensures that any request has a reviewer.
        </div>
      </div>
      <Handle
        style={handleStyle}
        type="target"
        id="topHandle"
        position={Position.Top}
      />
    </div>
  );
};
