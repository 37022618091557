import {
  Button,
  Callout,
  FormField,
  IconButton,
  Input,
  Select,
  Skeleton,
  SwitchField,
} from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import {
  type BookkeepingLockAfterUnit,
  useBookkeepingSettings,
} from 'modules/bookkeep/apis/useBookkeepingSettings';
import { useSetBookkeepingSettings } from 'modules/bookkeep/apis/useSetBookkeepingSettings';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

export function BookkeepingEditPayablesAfterExportSection() {
  const { t } = useTranslation('global');

  const [config, setConfig] = useState<{
    value: number | undefined;
    unit: BookkeepingLockAfterUnit | undefined;
    hasOptedIn: boolean;
  }>({
    value: undefined,
    unit: undefined,
    hasOptedIn: false,
  });

  const hasEditPayablesAfterExportFeature = useFeature(
    FEATURES.TMP_EDIT_PAYABLES_AFTER_EXPORT,
  );

  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: hasEditPayablesAfterExportFeature,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  useEffect(() => {
    if (
      bookkeepingSettings?.editPayablesAfterExport &&
      (bookkeepingSettings.editPayablesAfterExport.lockAfter !== config.value ||
        bookkeepingSettings.editPayablesAfterExport.lockAfterUnit !==
          config.unit ||
        bookkeepingSettings.editPayablesAfterExport.hasOptedIn !==
          config.hasOptedIn)
    ) {
      setConfig({
        value:
          bookkeepingSettings?.editPayablesAfterExport.lockAfter ?? undefined,
        unit:
          bookkeepingSettings?.editPayablesAfterExport.lockAfterUnit ??
          undefined,
        hasOptedIn: !!bookkeepingSettings?.editPayablesAfterExport.hasOptedIn,
      });
    }
  }, [bookkeepingSettings]);

  const [setBookkeepingSettings] = useSetBookkeepingSettings();

  const onConfirm = async () => {
    await setBookkeepingSettings({
      editPayablesAfterExport: {
        hasOptedIn: config.hasOptedIn,
        lockAfter: config.value,
        lockAfterUnit: config.unit,
      },
    });
  };

  const unitOptions = [
    {
      key: 'day',
      label: t('accounting.editPayablesAfterExport.unit.day'),
    },
    {
      key: 'week',
      label: t('accounting.editPayablesAfterExport.unit.week'),
    },
    {
      key: 'month',
      label: t('accounting.editPayablesAfterExport.unit.month'),
    },
    {
      key: 'quarter',
      label: t('accounting.editPayablesAfterExport.unit.quarter'),
    },
  ];

  if (!hasEditPayablesAfterExportFeature) {
    return;
  }

  if (bookkeepingSettingsQueryResult.status === 'loading') {
    return (
      <div className="box">
        <Skeleton width="75%" height="20px" className="my-[4px]" />
      </div>
    );
  }

  return (
    <>
      <div className="box">
        <SwitchField
          label={t('accounting.editPayablesAfterExport.title')}
          helpText={t('accounting.editPayablesAfterExport.content')}
          fit="parent"
          isChecked={config.hasOptedIn}
          onChange={async () => {
            setConfig((state) => ({
              ...state,
              hasOptedIn: !config.hasOptedIn,
            }));
            await setBookkeepingSettings({
              editPayablesAfterExport: {
                hasOptedIn: !config.hasOptedIn,
              },
            });
          }}
        />

        {bookkeepingSettingsQueryResult.status === 'error' && (
          <Callout
            title={t('accounting.editPayablesAfterExport.error')}
            variant="alert"
            className="mt-m"
          />
        )}

        {config.hasOptedIn && (
          <div className="mt-m flex flex-col gap-s">
            <FormField label={t('accounting.editPayablesAfterExport.field')}>
              <div className="flex flex-col gap-s">
                <p className="leading-5 text-neutral-dark">
                  {t('accounting.editPayablesAfterExport.fieldHelp')}
                </p>

                <div className="flex flex-row items-center gap-xs">
                  <Input
                    placeholder="30"
                    type="number"
                    className="w-[140px]"
                    value={config.value ?? ''}
                    min={1}
                    max={999}
                    onChange={(event) => {
                      const value = event.target.valueAsNumber;
                      setConfig((state) => ({
                        ...state,
                        value,
                      }));
                    }}
                  />

                  <Select
                    className="w-[140px]"
                    placeholder={t(
                      'accounting.editPayablesAfterExport.unit.day',
                    )}
                    options={unitOptions}
                    value={unitOptions.find(
                      (option) => option.key === config.unit,
                    )}
                    onSelect={({ key }) => {
                      setConfig((state) => ({
                        ...state,
                        unit: key as BookkeepingLockAfterUnit,
                      }));
                    }}
                  />

                  {config.unit && config.value && (
                    <IconButton
                      iconName="cross"
                      variant="borderless"
                      aria-label={t('misc.delete')}
                      onClick={() => {
                        setConfig((state) => ({
                          ...state,
                          value: undefined,
                          unit: undefined,
                        }));
                      }}
                    />
                  )}
                </div>
              </div>
            </FormField>

            <Button
              isDisabled={
                !config.unit ||
                !config.value ||
                (bookkeepingSettings?.editPayablesAfterExport.lockAfter ===
                  config.value &&
                  bookkeepingSettings?.editPayablesAfterExport.lockAfterUnit ===
                    config.unit)
              }
              text={t('accounting.editPayablesAfterExport.save')}
              variant="primary"
              className="w-[fit-content]"
              onClick={async () => {
                await onConfirm();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
