import { useMemo } from 'react';
import {
  type QueryKey,
  useQuery as useReactQuery,
  useQueryClient,
} from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';

import { type QueryClient } from '../client';
import { getFetcher } from '../fetcher';
import { type QueryRequest } from '../query';
import { type QueryError } from '../queryError';
import { type QueryState } from '../queryState';

type RefetchInterval<TRawResult> =
  | number
  | ((data?: TRawResult) => number | false)
  | false;

type BaseProps<
  TResult extends object | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object | undefined = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TRawError = TError,
> = {
  key: QueryKey;
  isEnabled?: boolean;
  request: QueryRequest;
  reshapeError?(error: QueryError<TRawError>): QueryError<TError>;
  options?: {
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult;
      client: QueryClient;
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
    }): void;
    refetchInterval?: RefetchInterval<TRawResult>;
    refetchOnWindowFocus?: boolean;
    /** In milliseconds */
    cacheTime?: number;
    /** In milliseconds – default: 0 */
    staleTime?: number;
    initialData?(): TRawResult | undefined;
    suspense?: boolean;
    structuralSharing?: boolean;
  };
};

export function useQuery<
  TResult extends object | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object | undefined = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TRawError = TError,
>(
  arguments_: {
    reshapeData(data: TRawResult): TResult;
  } & BaseProps<TResult, TRawResult, TError, TRawError>,
): QueryState<TResult, TError>;

export function useQuery<
  TResult extends object | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TRawError = TError,
>(
  arguments_: {
    reshapeData?: undefined;
  } & BaseProps<TResult, undefined, TError, TRawError>,
): QueryState<TResult, TError>;

export function useQuery<
  TResult extends object | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TRawResult extends object | undefined = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TError = any,
  TRawError = TError,
>({
  key,
  isEnabled = true,
  request,
  reshapeData,
  reshapeError = (error: QueryError<TRawError>): QueryError<TError> =>
    error as QueryError<TError>,
  options,
}: {
  key: QueryKey;
  isEnabled?: boolean;
  request: QueryRequest;
  reshapeData?(data: TRawResult): TResult;
  reshapeError?(error: QueryError<TRawError>): QueryError<TError>;
  options?: {
    onSuccess?(successBag: {
      rawData: TRawResult;
      data: TResult | TRawResult;
      client: QueryClient;
    }): void;
    onError?(errorBag: {
      error: QueryError<TError>;
      client: QueryClient;
    }): void;
    refetchInterval?: RefetchInterval<TRawResult>;
    refetchOnWindowFocus?: boolean;
    /** In milliseconds */
    cacheTime?: number;
    /** In milliseconds – default: 0 */
    staleTime?: number;
    initialData?(): TRawResult | undefined;
    suspense?: boolean;
  };
}) {
  const queryClient = useQueryClient();

  const companyId = useCompanyId();

  const fetcher = getFetcher<TRawResult>({
    companyId,
    getRequest: () => ({
      ...request,
      method: 'get',
    }),
  });

  const queryState = useReactQuery<TRawResult, QueryError<TRawError>>(
    key,
    fetcher,
    {
      ...options,
      retry: false,
      refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
      enabled: isEnabled,
      onSuccess: (data) =>
        options?.onSuccess?.({
          rawData: data,
          data: reshapeData ? reshapeData(data) : data,
          client: queryClient,
        }),
      onError: (error) =>
        options?.onError?.({
          error: reshapeError(error),
          client: queryClient,
        }),
    },
  );

  return useMemo(() => {
    if (
      queryState.status === 'loading' ||
      queryState.status === 'idle' ||
      !isEnabled
    ) {
      return {
        status: 'loading',
      };
    }

    if (queryState.status === 'error') {
      // Redirect to login page if unauthorized errors
      if (
        queryState.error.type === 'RequestError' &&
        queryState.error.status === 401
      ) {
        redirectToLogin();
        return {
          status: 'loading',
        };
      }

      return {
        status: 'error',
        error: reshapeError(queryState.error),
      };
    }

    return {
      status: 'success',
      data: reshapeData ? reshapeData(queryState.data) : queryState.data,
    };
  }, [queryState.data, queryState.status, reshapeData]);
}

const redirectToLogin = () => {
  window.location.assign(
    `/auth/login?session-expired=1&targetUrl=${encodeURIComponent(
      window.location.href,
    )}`,
  );
};
